export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima volta qui?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero password"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono o e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupero password"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo nome:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente trovato"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo da"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo a"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri/Commento"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna operazione trovata"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo delle operazioni"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pezzi per un totale di"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi di operazioni"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti, pagamento dei servizi"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti interni"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento della fattura"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti tra i propri conti"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di assegni cripto"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione di assegni cripto"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata/Uscita"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscita"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= uguale"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ diverso"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> maggiore"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ maggiore o uguale"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< minore"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ minore o uguale"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato con successo"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle operazioni"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica del conto"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio in borsa"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica indirizzo"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti all'interno del proprio portafoglio"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto digitale"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima autorizzazione:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preleva"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password di conferma"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per confermare le operazioni è necessario creare una password di conferma."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti popolari"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizie"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimi"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di registrazione"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermato"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibile al pubblico"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel gruppo"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi di pagamento"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di trasferimento"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto da ricaricare"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica conto"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del trasferimento"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica il trasferimento"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sui trasferimenti"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istruzioni"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica indirizzo"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo da ricaricare"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla lista delle attività"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del trasferimento"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti operazione"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle operazioni"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei conti"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore sconosciuto"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei token"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di trasferimento:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione della transazione"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della protezione:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva come modello"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento a un altro utente"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di protezione è il codice che il destinatario deve inserire per completare la transazione. Il trasferimento protetto sarà subito visibile nella sezione 'Storia delle operazioni', ma il denaro potrà essere utilizzato solo dopo l'inserimento del codice di protezione nella scheda della transazione. Se il pagamento scade, i fondi vengono restituiti al mittente."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del trasferimento"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti tra i propri conti"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti all'interno del portafoglio"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscita"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo indirizzo"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo conto"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina selezionati"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei conti"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del conto"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare i conti?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il conto?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto trovato"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina indirizzo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare gli indirizzi?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare l'indirizzo?"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di indirizzo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di conto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea indirizzo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea conto"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un nuovo indirizzo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un nuovo conto"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione anti-phishing è un meccanismo di protezione aggiuntivo durante l'uso del portafoglio."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase di benvenuto che imposti verrà mostrata nella parte superiore di ogni pagina del portafoglio."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase corretta indicherà che ti trovi sul sito corretto, mentre una frase errata sarà un segnale: non eseguire operazioni, contatta immediatamente il servizio di sicurezza del nostro sistema."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva anti-phishing:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase anti-phishing:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modifiche avranno effetto al prossimo accesso"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione dell'accesso tramite indirizzo IP impedirà a te (o a un malintenzionato) di accedere al sistema da un indirizzo non incluso nella lista degli indirizzi autorizzati."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizione dell'accesso al portafoglio tramite indirizzi IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi indirizzi"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocco di indirizzi: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista degli indirizzi"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da indirizzo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A indirizzo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proibisci"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permetti"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'indirizzo IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo IP inserito non valido"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP o blocco di indirizzi specificato."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun indirizzo IP autorizzato per l'accesso. Non potrai accedere al tuo conto."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso tramite IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La parola magica è una configurazione di sicurezza aggiuntiva."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se la parola magica è attivata, ogni volta che accedi al sistema, ti verrà chiesto di inserire alcuni caratteri (in base alla loro posizione) della parola magica."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi la parola magica:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'accesso al portafoglio:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante il pagamento tramite merchant:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante l'uso della carta:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola magica"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chiavi monouso aumentano significativamente la sicurezza del sistema."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento dell'accesso al programma, ti verrà chiesto non solo di inserire il login e la password, ma anche la chiave monouso successiva. L'attivazione avviene in sequenza. L'ultima chiave è valida fino a quando non crei una nuova serie di chiavi."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa chiavi monouso"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista delle chiavi"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi monouso"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nuova password\" e \"Conferma password\" non coincidono."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio password"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da 4 a 63 caratteri. Sono ammessi caratteri dell'alfabeto latino, numeri e simboli"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nuova password\" e \"Conferma password\" non coincidono."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non deve contenere caratteri cirillici"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione della password di conferma"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio della password di conferma"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da 4 a 65 caratteri. Sono ammessi caratteri dell'alfabeto latino, numeri e simboli"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nel caso in cui dimentichi la password per accedere al portafoglio, potrai recuperarla rispondendo alla domanda segreta."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi cambiare la domanda/risposta segreta."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda segreta"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua domanda:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda segreta"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua domanda"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia di scambio:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di scambio:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio al mercato - verrà utilizzato lo scambio al miglior prezzo disponibile al momento dell'esecuzione dell'ordine. Attenzione, l'importo da ricevere può variare e differire da quello calcolato in base agli ordini al momento dello scambio."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio a prezzo fisso avviene in base all'importo e al tasso da te specificati. Verrà creato un ordine. Se il tasso specificato differisce significativamente da quello di mercato, il completamento dell'ordine potrebbe richiedere molto tempo."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso (previsione):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso non definito,"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["si consiglia di specificare il tasso autonomamente"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa tutto"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al mercato"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(scambio rapido al miglior prezzo disponibile)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso fisso"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(crea un ordine con il tasso specificato)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto disponibile"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variazione nelle 24 ore"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oscillazioni nelle 24 ore"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume degli scambi nelle 24 ore"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo non può essere pari a zero!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambia"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per visualizzare i dati di riepilogo, seleziona una coppia di scambio"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile scambiare asset identici"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitazioni"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borsa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato. Riepilogo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposte simili"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposte corrispondenti"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danno"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevono"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei ordini"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime transazioni"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissione"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna offerta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ordine"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato al 100%"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione non iniziata"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione iniziata"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato completamente"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato e annullato"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuto"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli ordini"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli ordini"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissione"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ordine"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo minimo da dare"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo da dare"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo minimo da ricevere"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo da ricevere"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di tentativi di completare la transazione"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da dare"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da ricevere"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di accredito"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei subdealer"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo da"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo a"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di data non selezionato"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finito"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quale parte è fissa e quale è variabile"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo da dare è fisso"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'importo da ricevere è fisso"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ordine"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente desidera scambiare rapidamente al miglior prezzo disponibile"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio a tasso fisso"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione non iniziata"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione iniziata"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato al 100%"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullato completamente"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente completato e annullato"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da dare"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da ricevere"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di data"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dare importo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevere importo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazioni"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna offerta"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID venditore"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice dell'offerta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-asset"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore verificato"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo per utenti verificati"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile effettuare la transazione con se stessi"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona asset"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missione"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei cripto-asset"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun cripto-asset"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione token"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercato"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primario"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondario"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correlato con un altro attivo"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome è il nome completo del cripto-asset che tutti vedranno: l'emittente, gli acquirenti, i venditori, il processing, ecc. Una stringa lunga non più di 40 caratteri"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker è il nome abbreviato del cripto-asset, un'abbreviazione di 3-10 caratteri"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la categoria che meglio si adatta al tuo cripto-asset"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formati supportati: png, jpg, jpeg. Dimensione massima del file: 1 MB, 500*500 pixel"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrivi il motivo per cui l'emittente rilascia questo cripto-asset, in quale settore opera o opererà, qual è la sua missione"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopo / missione / destinazione:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo per unità:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di cifre decimali per l'importo. Ad esempio, la precisione della maggior parte delle valute fiat, come il dollaro USA o l'euro, è pari a 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di unità del cripto-asset emesso. L'importo dell'emissione sarà immediatamente disponibile sul tuo conto dopo il completamento dell'operazione di creazione del cripto-asset."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo dichiarato per unità:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanzia:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona che assume le obbligazioni:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisione di emissione del cripto-asset:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta le operazioni nella blockchain:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di blockchain:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita operazioni con il cripto-asset:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizioni/Sanzioni:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica a quali cittadini o residenti fiscali dei paesi dell'elenco è vietato utilizzare il cripto-asset:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre restrizioni e condizioni:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di hosting:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo del white paper:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri contatti:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al precedente"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea cripto-asset"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e destinazione"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche finanziarie"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta nella blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti e restrizioni"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non definito"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legame fisso con valute fiat e altri asset"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emittente (persona che emette il cripto-asset)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerente (persona che distribuisce il cripto-asset)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Io (includi sponsorizzazione)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuzione del cripto-asset"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cripto-asset rappresenta un valore digitale o un diritto che può essere trasferito e conservato elettronicamente utilizzando la tecnologia blockchain. I cripto-asset NON includono valute fiat, titoli e altri strumenti finanziari e asset soggetti a regolamentazione statale separata."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica risorsa crittografica"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministrazione"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del rifiuto di accettazione"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto dell'offerente"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto dell'offerente nella valuta vincolante"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Offerente è un rappresentante dell'emittente di un bene nelle operazioni di acquisto/vendita sul mercato primario. \nIl conto è passivo. \nPuò essere un conto di emissione di asset"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il conto è passivo, collegato in valuta. \nDeve appartenere allo stesso utente dell'account precedente"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti agli utenti di acquistare un bene presso gli sportelli bancomat"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di acquisto post-vendita"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sul mercato secondario vengono effettuate tramite gateway"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo di vendita post-vendita"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sul mercato secondario vengono effettuate tramite gateway"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti transazioni con un asset in borsa"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti transazioni di risorse tramite P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei un imprenditore individuale o un rappresentante di una persona giuridica, devi scaricare il file della decisione sul rilascio di una risorsa digitale in formato PDF. \nLa soluzione deve contenere tutte le principali caratteristiche del bene virtuale ed essere firmata con la firma elettronica del singolo imprenditore o di questa persona giuridica. \nUn esempio di decisione di emettere una risorsa digitale può essere trovato sulle pagine del nostro sito web nella sezione \\\"Documenti\\\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizionamento di una risorsa virtuale"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione notifiche"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica delle operazioni"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzazione (accesso al portafoglio personale, app mobile, ecc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica per l'evento \"Esecuzione di operazioni finanziarie tramite carta\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese (qualsiasi pagamento, trasferimento, pagamento di servizi, ecc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevimento di fondi"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione di messaggi nella posta interna"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione di una fattura"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica delle impostazioni di sicurezza"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma delle operazioni con codice dal messaggio"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma dell'autorizzazione con codice dal messaggio"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma di \"Esecuzione di operazioni finanziarie tramite carta\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma della modifica delle impostazioni di sicurezza con codice dal messaggio"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma delle spese con codice dal messaggio"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiche non salvate, salvare?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non salvare"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di ricezione"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di elaborazione"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di pagamenti"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui riusciti"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui falliti"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti di massa"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore di servizi"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1° parametro"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun pagamento trovato"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi effettuare più pagamenti contemporaneamente. Per farlo, devi prima creare un file con la descrizione dei pagamenti nei formati \"XLS\", \"XLSX\", \"CSV\" o \"XML\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizza file"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul file"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pagamenti: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per un importo totale di: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File dei pagamenti"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esegui pagamenti"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento file dei pagamenti"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti e trasferimenti di massa"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il servizio di pagamenti di massa consente di trasferire rapidamente fondi ad altri utenti ed effettuare operazioni di pagamento a favore dei fornitori di servizi."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella sezione"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica file dei pagamenti"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puoi caricare un file nei formati Excel (XLS, XLSX, CSV) o XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle attività"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consente di visualizzare lo stato delle richieste di pagamento di massa create in precedenza."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi creare un file in un editor di fogli di calcolo come Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica esempio di file"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note importanti:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato file - CSV, XLS o XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file deve essere senza intestazioni."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per evitare che i numeri lunghi vengano visualizzati in formato esponenziale (ad esempio, \"5,46546E+15\") e che vengano troncati gli zeri iniziali, puoi anteporre un singolo apice (') al numero, oppure impostare la cella in formato testo prima di inserire i dati."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per i pagamenti ai fornitori di servizi"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle colonne nel file:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Indicato dall'elenco degli operatori riportato"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Per tutti gli operatori di telefonia mobile puoi indicare il codice operatore 7000 - in questo caso l'operatore verrà determinato automaticamente in base al numero di telefono.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nel ticker dell'asset da cui viene effettuato il trasferimento. Se non esiste tale ticker, verrà applicato automaticamente il tasso di conversione del sistema."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al pagamento"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Viene specificato il valore del parametro richiesto dall'operatore, ad esempio, il numero di telefono. Molti operatori si limitano a un solo parametro di pagamento, ma se il numero di parametri è maggiore di uno, devi specificarli tutti nelle colonne adiacenti alla prima."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi vedere i parametri del pagamento accedendo al pagamento di qualsiasi operatore:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per i trasferimenti di massa agli utenti"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Indicato come 0 o non indicato affatto."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nel ticker del conto da cui viene trasferito l'asset. Se non esiste tale ticker, verrà applicato automaticamente il tasso di conversione del sistema."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al pagamento"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto del destinatario"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Qui è indicato il numero di conto dell'utente a cui deve essere effettuato il trasferimento (20 cifre)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato delle colonne per l'emissione di prestiti di massa da parte di un agente di credito"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Questa riga indica che i pagamenti sono prestiti."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". L'importo è indicato nella valuta del prodotto di credito."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento al credito"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Non è obbligatorio, puoi lasciarlo vuoto; lunghezza non superiore a 255 caratteri."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del mutuatario"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Nota che il mutuatario deve avere un passaporto digitale Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice del prodotto di credito"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Puoi visualizzarlo nell'elenco dei prodotti di credito durante l'emissione manuale del prestito."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Solo il numero dei periodi (i periodi specifici sono determinati nel prodotto di credito, ad esempio, possono essere giorni, settimane, mesi, ecc.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice operatore (numero del modulo)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Il completamento di questo e dei campi successivi non è obbligatorio; se non vengono completati, il mutuatario potrà successivamente effettuare il prelievo del prestito tramite il proprio account personale."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullare in caso di errore"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Annullare (1) il prestito emesso se la transazione di prelievo non riesce, oppure lasciarlo per il prelievo successivo (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Viene specificato il valore del parametro richiesto dall'operatore, ad esempio, il numero di telefono. Molti operatori si limitano a un solo parametro di pagamento, ma se il numero di parametri è maggiore di uno, devi specificarli tutti nelle colonne adiacenti alla prima."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro del pagamento N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo messaggio"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna richiesta"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo del messaggio"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi caricare uno o più file. Dimensione massima del file - 10MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassa"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto tecnico"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste attive"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivio"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione dall'operatore"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta creata"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoro in corso, attendere una risposta dall'operatore"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta chiusa"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta chiusa"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo messaggio"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo messaggio dall'operatore"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo messaggio"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia all'archivio"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun modello"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il modello №"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina modello"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinomina"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul corrispondente"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Conto / E-mail / Telefono"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Indirizzo cripto / E-mail / Telefono"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul pagamento"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma di controllo"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei negozi"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun negozio trovato"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea codice widget"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea link di pagamento"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina negozio"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero eliminare il negozio"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrare negozio"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di pagamento"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrare negozio"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il negozio elettronico registrato nel sistema può ricevere pagamenti dai clienti tramite il nostro sistema di merchant."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vengono registrati come negozi i negozi elettronici, i punti di cambio e qualsiasi altra risorsa internet che desideri accettare pagamenti."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome o marchio"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL del negozio"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breve descrizione del negozio"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di servizio"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio attivo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiave segreta"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti pagamenti automatici"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia risultati allo script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo script"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna dopo pagamento riuscito"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo pagamento NON riuscito"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 30 caratteri. Caratteri consentiti: lettere latine, numeri"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzato per verificare l'integrità e l'autenticità dei dati trasmessi dal sistema di pagamento (quando il negozio invia l'utente al merchant) e dal negozio (quando riceve i risultati del pagamento)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'elenco dei negozi"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco fatture"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna fattura trovata"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterno"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinonimi"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se compilato, l'annuncio sarà disponibile solo per gli utenti dei paesi indicati"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega all'asset"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviazione"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione del trasferimento"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annuncio è attivo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo tramite link"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo per utenti verificati"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precedente"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblica"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrizioni"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione annuncio"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo asset esterno"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non collegare"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riduci tasso"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna indietro"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun conto in tale asset"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea conto"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifica tasso"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso corretto"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riduci tasso"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna indietro"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo superiore al massimo"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove e come vuoi ricevere il trasferimento. Ad esempio: Trasferisci tramite l'app bancaria al numero di telefono +399990001111 (destinatario Alessandro N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevo"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli richiesti"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali dettagli deve inviare il ricevente per poter effettuare il trasferimento"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un attivo"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un attivo"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa desiderate ricevere: fondi in un attivo interno, ad esempio BTC, o in un attivo esterno, ad esempio TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentate le caratteristiche di questa transazione, ad esempio: \\\"L'attivo esterno viene trasferito solo in un incontro personale\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di valori a cui appartiene l'attivo esterno, ad esempio \\\"Contanti\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'attivo da visualizzare sulla piattaforma"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'attivo può anche essere chiamato con parole della lista"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondenza con un attivo esistente"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio: litri, pezzi, ore, scatole"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad esempio: L, pz, ore, scat"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quante cifre possono esserci dopo la virgola nei valori dell'attivo"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete scelto di creare un attivo non ancora esistente, si prega di compilare le sue caratteristiche"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo concesso alle parti per adempiere le condizioni"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un attivo interno è un attivo che esiste nel sistema \\\"New Reality\\\". Un attivo esterno è un attivo che non esiste nel sistema \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non confrontato"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla transazione"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma partecipazione alla transazione"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuta la transazione"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estendi tempo di attesa"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento transazione"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato utente nella transazione non definito. Qualcosa è andato storto"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi arbitrato"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta arbitrato"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifica esecuzione trasferimento"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma ricezione trasferimento"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta la transazione"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia valutazione"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione № "])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oggi"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domani"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dai"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazioni"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficio"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice transazione"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione attiva"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie transazioni"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'asset"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun annuncio"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei annunci"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei annunci"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo annuncio"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterno"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interno"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dò"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevo"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limiti"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo del conto"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta transazione"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa delle azioni delle parti"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasso"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dai"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi transazione"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea conto"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non selezionato"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo fuori intervallo: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da dare"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset da ricevere"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asset"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ricevo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset interni"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterni"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esterno"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionato:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interno"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esterno"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi arbitrato"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieri"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset esterni"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'asset"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo da"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo a"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun asset trovato"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegato all'asset"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuncio"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massimo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite importo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di attesa (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuncio attivo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo tramite link"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile solo per utenti verificati"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica asset esterno"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinonimi"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collega all'asset"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di misura"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviazione"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisione"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutto"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomat"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per espandere la tabella a schermo intero"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco dei validatori"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea criptomat"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica criptomat"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni principali"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principale"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari di apertura"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del luogo di installazione"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario normale"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario non normale"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di lavoro"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunedì"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martedì"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercoledì"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giovedì"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venerdì"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabato"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenica"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausa pranzo"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti password"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza account esistenti"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovi account"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni principali"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di lavoro (informazione di riferimento)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna macchina crittografica trovata"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del bancomat"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitudine"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitudine"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea di assistenza"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del sub-rivenditore"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di transito"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un crittografo"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare un crittografo"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni elettronici"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea assegno"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun assegno"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un assegno elettronico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di addebito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore nominale di ogni assegno"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di assegni"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni creati"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione di un assegno elettronico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di accredito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di attivazione"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, si è verificato un errore!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro bancomat"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi raccolta"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mostrare le raccolte d'archivio"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mostrare le raccolte annullate"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del punto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna raccolta"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domanda di riscossione presentata"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunta raccolta"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di base"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nozioni di base"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del punto"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di carte per applicazione"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettatore di banconote"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collezionisti di contanti"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota per i collezionisti"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla raccolta"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento fuori ordine"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituire la cassetta dell'accettatore di banconote"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento alla cassetta"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere tutte le carte dal cassetto del dispenser delle carte di scarto"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovere tutte le carte dal tubo"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero del punto è un campo obbligatorio che deve essere compilato"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID cassetta 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di banconote nella cassetta 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo nella cassetta 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione della cassetta 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di fatture nella cassetta 2"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account personale"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account business"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondo nome: (se presente)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini e apostrofi."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini, spazi e apostrofi."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine, trattini, virgolette, spazi, numeri e apostrofi."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammesse lettere latine (maiuscole e minuscole), numeri, simboli _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti password"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non corrispondono"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati utente"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo email o numero di telefono corretto. Il numero di telefono deve essere inserito nel formato internazionale, ad esempio: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione avvenuta con successo"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accedere al tuo account personale"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account sarà verificato da un nostro operatore, ti informeremo sui risultati della sua attivazione."])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una email con un link per confermare la registrazione è stata inviata"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al tuo indirizzo email"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo il primo accesso ti sarà chiesto di confermare il numero di telefono"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente non trovato"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione utenti"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transazione"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri generali"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dei saldi sui conti"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla fine dell'operazione:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All'inizio dell'operazione:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri aggiuntivi"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazioni nella blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimo tentativo:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione della transazione"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della protezione:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fino a"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di protezione:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri del modello"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del modello:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla operazione"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti operazione"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva modello"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero della transazione"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispondente"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data del pagamento"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del modulo di pagamento"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata/Uscita"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dei saldi degli indirizzi"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma accredito"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restituisci denaro al mittente"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operazione sospetta"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di azione:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente non trovato"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la password di conferma"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti operazione"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle operazioni"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco conti"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore sconosciuto"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei asset"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul nuovo asset"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco degli asset virtuali"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserimento:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona indirizzo"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distretto"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio urbano"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento aggiuntivo dell'indirizzo"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specificazione"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero civico"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di supporto tecnico"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi messaggio"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi caricare uno o più file. Dimensione massima del file - 10MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo del messaggio"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione widget per"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento in"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni in caso di successo"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni in caso di insuccesso"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generazione codice/link di pagamento"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero operazione negozio"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametro aggiuntivo"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di successo"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di errore"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL di notifica"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove il cliente verrà reindirizzato dopo un pagamento riuscito"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove il cliente verrà reindirizzato in caso di errore"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dove verrà inviata la notifica del risultato del pagamento"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fissa importo"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link di pagamento"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentazione"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del conto di pagamento in minuti, predefinito 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del link"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica negozio"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vedi questo, il modulo non è stato ricevuto (ma non dovresti vedere questo)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissione aggiuntiva"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-asset"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di copertura"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto di commissione"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione dell'emissione"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commento"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da detrarre"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da accreditare"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona file"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formati consentiti:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato sconosciuto"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione file troppo grande, dimensione massima:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona o trascina qui i file da caricare"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File caricati"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultato:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna stato"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link utili"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre impostazioni di sicurezza"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta conferma"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorizzazione deve essere confermata con una chiave monouso"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorizzazione deve essere confermata con una parola magica"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La registrazione deve essere confermata con un codice inviato via SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa da"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caratteri"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non valido"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrato:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totale"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per pagina"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma pagamento"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola commissione di ritorno"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restituisci pagamento"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash nella blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rischio AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da accreditare"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei d'accordo con le condizioni modificate, conferma l'accredito:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se queste condizioni non ti sono favorevoli, puoi restituire i fondi al conto del mittente al netto delle spese di ritorno:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per un importo di"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha un rischio AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quindi la commissione di accredito è stata ricalcolata"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["così, l'importo da accreditare è"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun nuovo accredito trovato"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pagamento"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorno"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])}
      }
    }
  }
}