<template>
    <div class="light">
        <div class='d-flex justify-content-between neue'>
            <h3>{{ $t('components.service.modal.SupportList.1') }} #{{ this.content.topic.code }} {{ $t('components.service.modal.SupportList.2') }} {{ this.content.topic.dt_create }}</h3>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div v-if="!this.loader">
            <h4 class='p-0 mt-4 color-menu align-self-start'>{{ $t('components.service.modal.SupportList.3') }}</h4>
            <div class="scroll-wrapper"
                    v-on:touchstart="this.scrollHandler($event)" 
                    v-on:touchmove.prevent="this.scrollHandler($event)" 
                    v-on:touchend="this.scrollHandler($event)" 
                    v-on:wheel.prevent="this.scrollHandler($event)">
            <div class='scroll-menu container-fluid mt-1 p-0 g-3 d-flex flex-column' ref="scroll_menu" :style="`top: ${this.scrollPos}px;`">
                <div v-for="(row, index) in this.msgs" :key="index" :class="{'align-self-start': row.admin == '0', 'align-self-end': row.admin == '1'}" class="d-flex flex-column">
                    <div class="card p-2 mb-2 d-flex flex-column">
                        <span class="fs_08 wraptext" :class="{'color-menu': row.admin == '0', 'color-warning': row.admin == '1'}">{{ row.author }} - {{ row.dt }}</span>
                        <span class="mb-0 wraptext">{{ row.text }}</span>
                        <div v-for="(file, index) in row.files" :key="index" class="d-flex flex-row pt-1 pointer" v-on:click="this.DownloadFile(file)">
                            <i class='item-i contain c24 mr-1' :class='"file_download"'></i>
                                {{ file.name }}
                        </div>
                    </div>
                </div>
                <div class="loader-r-cc2">
                    <Loading :active="this.smallloader"
                        :is-full-page="false"
                        :height='20'
                        :width='50'
                        :loader='"dots"'
                        :color='"#008D3E"'
                        :opacity='0'
                    />
                </div>
            </div>
            </div>
            <div v-if="this.content.topic.status != 'archive'" class='container-fluid mt-2 p-0 g-3'>
                <h4 class='p-0 color-menu'>{{ $t('components.service.modal.SupportList.4') }}</h4>
                <Input type='textarea' :label="$t('components.service.modal.SupportList.7')" id='message' :dataValue='this.data.message' @updateParentState="this.updateState" />
                <File 
                :key="this.f_key"
                :label="$t('components.service.modal.SupportList.5')"
                :arr='true'
                :single='false'
                :dataValue='this.data.file' 
                :returnFileObject="true"
                :id='"file"' 
                :maxSize="10"
                @updateParentState="this.updateState"
                :hint="$t('components.service.modal.SupportList.6')"/>  
            </div>
        </div>
        <div v-if="this.loader" class="loader-r-cc">
            <Loading :active="this.loader"
                 :is-full-page="false"
                 :height='15'
                 :width='50'
                 :loader='"dots"'
                 :color='"#008D3E"'
                 :opacity='0'
                />
        </div>
        <div v-if="this.content.topic.status != 'archive'" class="row flex-column flex-md-row container-fluid g-3 m-0 align-items-center justify-content-center input">
            <div class="col-sm-12 col-md-4">
                <Button :name="$t('components.service.modal.SupportList.8')" class='col-12' :disabled="!this.data.message" v-on:click="this.AddSupportMessage"  type='btn-warning btn'/>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Input from '@/components/service/Input';
import Button from '@/components/service/Button';
import File from '@/components/service/File';

export default {
    components: {
        Loading,
        Input,
        Button,
        File
        
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            t: false,
            msgs: [],
            data: {
                message: '',
                file: [],
            },
            loader: true,
            smallloader: false,
            f_key: 0,
            scrollPos: 0,
        }
    },
    mounted() {
        this.getMessages(); 
    },
    beforeUnmount() {
        if (this.t) {
            window.clearTimeout(this.t);
        }
    },
    methods: {
        getMessages() {
            this.axios.post('GetlistSupportMessages', {no_loader: true, data: {topic: this.content.topic.code}}).then(res => {
                this.loader = false;
                if (res.data.body.result === '0') {
                    this.msgs = res.data.body.messages;
                    this.data.file = [];
                    this.t = setTimeout(() => {this.updateMessages()}, 15000);
                } else {
                    this.close();
                }
            });
        },
        updateMessages() {
            this.axios.post('GetlistSupportMessages', {no_loader: true, data: {topic: this.content.topic.code, last_read_message: this.msgs[this.msgs.length - 1].inc}}).then(res => {
                res.data.body.messages.forEach(e => {
                    this.msgs.push(e);
                });
            })
            this.t = setTimeout(() => {this.updateMessages()}, 15000);
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        AddSupportMessage() {
            this.axios.post('AddSupportMessage', this.getData(),
            {headers: {
                    'Content-Type': 'multipart/form-data',
            }}).then(res => {
                if (res.data.body.result == '0') {
                    this.data.message = '';
                    this.data.file = [];
                    this.msgs = res.data.body.messages;
                    this.f_key =  this.f_key + 1
                    if (this.t) {
                        window.clearTimeout(this.t);
                    }
                    this.t = setTimeout(() => {this.updateMessages()}, 15000);
                    
                }
            });
        },
        DownloadFile(b64) {
            let data = `data:${b64.mime};base64,${b64.body}`;
            fetch(data).then(res => {
                res.blob().then( blob => {
                    let url = URL.createObjectURL(blob);
                    this.Down(url, b64.name);
                    URL.revokeObjectURL(url); 
                });
            })
        },
        Down(path, filename) {
            const anchor = document.createElement('a');
            anchor.href = path;
            anchor.download = filename;

            document.body.appendChild(anchor);


            anchor.click();

            document.body.removeChild(anchor);
        },
        getData() {
            let param = new FormData();
            param.append('topic', this.content.topic.code);
            param.append('message', this.data.message);
            
            this.data.file.forEach((e, i) => {
                param.append('file[]', e.fileObject[0]);
            })
            return param;
        },
        scrollHandler(e) {
            if (e.type == 'touchstart') {
                this.touchTarget = e.targetTouches[0]['screenY'];
                return;
            }
            if (e.type == 'touchend') {
                this.touchTarget = false;
                return;
            }
            let height = this.$refs.scroll_menu.clientHeight;
            if (e.type == 'touchmove') {
                if (this.touchTarget > e.targetTouches[0]['screenY']) {
                    this.scrollPos -= this.touchTarget - e.targetTouches[0]['screenY'];
                    this.touchTarget = e.targetTouches[0]['screenY'];
                } else if (this.touchTarget < e.targetTouches[0]['screenY']) {
                    this.scrollPos += e.targetTouches[0]['screenY'] - this.touchTarget;
                    this.touchTarget = e.targetTouches[0]['screenY'];
                }
                return;
            }
            if (e.deltaY > 0) {
                if (height > -1 * this.scrollPos + 150) {
                    this.scrollPos -= 40;
                }
            } else if (e.deltaY < 0) {
                if (this.scrollPos < 0) {
                    this.scrollPos += 40;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.loader-r-cc {
    min-height: 100px;
    position: relative;
}
.loader-r-cc2 {
    min-height: 15px;
    position: relative;
}
.card {    
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.01);
}
.wraptext {
    overflow-wrap: anywhere;
}

.fixed-wrapper {
    position: sticky;
}
.scroll-menu {
    position: absolute;
    left: 0;
    right: 0;
}
.scroll-wrapper {
    height: 40vh;
    overflow: hidden;
    -ms-overflow-style: none;
    position: relative;
}
.scroll-menu::-webkit-scrollbar {
    width: 0;
}
</style>