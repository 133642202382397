<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.number ? this.content.title + ' номер ' + this.content.number : this.content.title }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="col-12">
            <Input 
                type='text'
                :dataValue='this.content.name ? this.content.name : this.data.name'
                :label="'Имя'"
                id='name'
                @updateParentState="this.setValue"
            />
        </div>
        <div class="col-12 col-md-4 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button 
                :name="'Отмена'"
                class="col-12 col-sm-6"
                :class="this.$store.state.resize.w > 768 ? 'mr-1' : 'mb-2'"
                type='btn-outline-warning'
                v-on:click="this.close"
            />
            <Button 
                :name="'Сохранить'"
                class="col-12 col-sm-6"
                type='btn-warning'
                v-on:click="this.saveCollector" 
                :disabled="this.disabledSave"
            />
        </div>
    </div>
</template>

<script>
import Input from '@/components/service/Input';
import Button from '@/components/service/Button';
    export default {
        components: {
            Input,
            Button
        },
        props: {
            close: [Function],
        },
        data() {
            return {
                content: this.$store.state.modal.content,
                disabledSave: true,
                data: {
                    name: false,
                }
            }
        },
        methods: {
            setValue(arg) {
                this.data[arg.id] = arg.value;
                this.disabledSave = !this.checkRequiredFields();
            },
            checkRequiredFields() {
                if (this.data.name) {
                    return true;
                }

                return false;
            },
            saveCollector() {
                if (this.content.number) {
                    this.axios.post('SetparamsCollector', {data: {inc: this.content.number, name: this.data.name}}).then(res => {
                        if (res.data.body.result == '0') {
                            this.close();
                        }
                    });
                } else {
                    this.axios.post('AddCollector', {data: {name: this.data.name}}).then(res => {
                        if (res.data.body.result == '0') {
                            this.close();
                        }
                    });
                }
            }
        },
        mounted() {
            this.disabledSave = !this.checkRequiredFields();
        }
    }
</script>