<template>
    <div>
        <div class='d-flex justify-content-between neue'>
            <h4>{{ $t('components.service.modal.Emission.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3'>
            <Select  :label="$t('components.service.modal.Emission.2')"  id='custom_curr' :require="true" :dataValue='this.data.custom_curr' :keywords.sync='this.currs' @updateParentState="this.updateState"/>
            <Input type='textarea' :dataValue='this.data.comment' :label="$t('components.service.modal.Emission.6')" id='comment' @updateParentState="this.updateState"/>
            <Select  :label="$t('components.service.modal.Emission.3')"  id='cover_from_keyt' :keyt="true" :dataValue='this.data.cover_from_keyt' :keywords.sync='this.keywords' @updateParentState="this.updateState"/>
            <Select  :label="$t('components.service.modal.Emission.4')"  id='commission_from_keyt' :keyt="true" :dataValue='this.data.commission_from_keyt' :keywords.sync='this.keywords' @updateParentState="this.updateState"/>
            <Input type='text' :dataValue='this.data.emission_amount' :invalid='this.summWarn' :invalidHint='this.summWarn' :label="$t('components.service.modal.Emission.5')" id='emission_amount' @updateParentState="this.updateState"/>
            <Comiss :sum="this.data.emission_amount" 
                        ref="comiss"
                        @updateParentState='this.setSummWarn' 
                        @onSuccess="this.readyCheck"
                        :params = "this.setParams()" 
                        :cmd_name="'emission_custom_curr'" 
                        :precision="'2'"
                        :curr="'HRD'" 
                    />
            <div class="row container-fluid g-3 align-items-center justify-content-center input mt-2">
                <div class="col-sm-2">
                    <Button :name="$t('components.service.modal.Emission.7')" v-on:click="this.emissionDo" type='btn-warning btn' :disabled='!this.ready'/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';
import Comiss from '@/components/service/Comiss';
import Select from '@/components/service/Select';

export default {
    components: {
        Button,
        Input,
        Comiss,
        Select
    },
    props: {
        close: [Function],
    }, 
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    data () {
        return {
            curr: this.$store.state.modal.content.curr,
            currs: this.$store.state.modal.content.currs,
            keywords: [],
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            data: {
                comment: '',
                commission_from_keyt: '',
                cover_from_keyt: '',
                custom_curr: this.$store.state.modal.content.curr.curr_code,
                emission_amount: '',
            },
            summWarn: false,
            ready: false,
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    mounted() {

    },
    methods: {
        getKeyts(k) {
            this.keywords = k;
        },
        setParams() {
            let params = Object.assign({}, this.data);
            return params;
        },
        readyCheck(arg) {
            this.ready = arg;
        },
        setSummWarn(arg) {
            this.summWarn = arg
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        emissionDo() {
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('EmissionCustomCurr', {data: this.setParams()});
                    // this.axios.post('payMomental', {data: this.checkParams()}).then( res => {
                        // if (res.data.body.result == '0') {
                            // console.log(this.grp)
                            // res.data.body['type'] = 'pay';
                            // this.init();
                            // console.log(this.grp)
                            // this.getOperator(this.grp);
                            // this.$store.commit('MODAL_ACTION', true);
                            // this.$store.commit('MODAL_TYPE', 'pay_status');
                            // this.$store.commit('MODAL_CONTENT', res.data.body);
                        // }
                    // })
                }},
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .answer_content :deep(a) {
            color: $color-href !important;
    }
    
</style>