<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4 class="m-0">{{ this.user_pos == 'plaintiff' ? $t('views.P2P.RequestP2PArbitrage.1') : $t('views.P2P.RequestP2PArbitrage.4') }}</h4>
            <i class='big item-i x_red pointer c24' v-on:click='this.close'></i>
        </div>
        <div class="arbitrage-container">
            <div class="mb-3 neue-roman arbitrage-wrapper">
                <GetInputs
                    :fields='this.fields' 
                    class='tst'
                    :onAlias="true"
                    @updateParentState="this.updateState"
                />
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center buttons-container">
            <Button 
                class="btn-sm" 
                :name="$t('views.P2P.RequestP2PArbitrage.2')" 
                type="btn-outline-warning" 
                @click="this.close"
            />
            <Button 
                class="btn-sm" 
                :name="$t('views.P2P.RequestP2PArbitrage.3')" 
                type="btn-warning"
                :disabled="this.sendDisabled"
                @click="this.user_pos == 'plaintiff' ? this.sendArbitrage() : this.agreeArbitrage()"
            />
        </div>
    </div>
</template>

<script>
    import GetInputs from '@/components/service/GetInputs';
    import Button from '@/components/service/Button';

    export default {
        components: {
            GetInputs,
            Button
        },
        props: {
            close: [Function],
        },
        data () {
            return {
                content: this.$store.state.modal.content,
                user_pos: this.$store.state.modal.content.userArbitragePos,
                fields: [],
                fields_values: {},
                required_fields: [],
                sendDisabled: true,
            }
        },
        methods: {
            updateState(arg) {
                this.fields_values[arg.id] = arg.value;
                this.sendDisabled = this.checkRequiredFields();
            },
            checkRequiredFields() {
                let checked = false;
                this.required_fields.forEach(field => {
                    const fieldId = field.alias;
                    if (!this.fields_values[fieldId]) {
                        checked = true;
                    }
                })

                return checked;
            },
            getData() {
                let dataObject = {};
                dataObject.swap = this.content.dealCode;
                dataObject = {...dataObject, ...this.fields_values};
                return dataObject;
            },
            sendArbitrage() {
                if (!this.sendDisabled) {
                    this.axios.post('RequestP2PArbitrage', {data: this.getData()}).then(res => {
                        if (res.data.body.result == '0') {
                            this.content.updateMessages();
                            this.close();
                        }
                    });
                }
            },
            agreeArbitrage() {
                this.axios.post('AgreeP2PArbitrage', {data: this.getData()}).then(res => {
                    if (res.data.body.result == '0') {
                        this.content.updateMessages();
                        this.getParamsSwap();
                        this.updateSwapStatus();
                        this.close();
                    }

                })
            },
        },
        created() {
            this.axios.post('GetFormFields', {data: {payform: this.user_pos == 'plaintiff' ? '7749' : '7708'}}).then(res => {
                this.fields = res.data.body;
                this.required_fields = this.fields.filter(field => field.obligatory_conditions == '1');
                this.sendDisabled = this.checkRequiredFields();
            });

            console.log('modal arbitrage user arb status', this.user_pos);
        }
    }
</script>

<style lang="scss">
    .arbitrage-container {
        max-height: 60vh;

        @media (max-width: 767px) {
            max-height: 80vh;
        }
    }
    .arbitrage-wrapper {
        max-height: 45vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
            background: white;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-menu;
            border-radius: 20px;
            border: 1px solid white;
        }

        @media (max-width: 767px) {
            max-height: 52vh;
        }
    }
</style>