<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4 v-if="this.to_widget"> Генерация кода для виджета {{ this.content.name }}</h4>
            <h4 v-else> Генерация платежной ссылки {{ this.content.name }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="mb-3">
            <h5 class="fs_10">{{ $t('components.service.modal.PayHref.17') }}: <span class='href' v-on:click="this.redir(this.doc)">{{ this.doc }}</span></h5>
        </div>
        <div class="mb-3">
            <Select :label="$t('components.service.modal.WidgetData.3')" :require="true"  id='on_keyt_shop' :dataValue='this.data.on_keyt_shop' :keyt='true'  :first='true' :keywords.sync='this.keywords' @updateParentState="this.updateState"  :hint="'Счет, куда будут зачислены поступившие средства'"/>
            <div class="d-flex flex-column flex-sm-row mt-3 p-0 mr-0  g-3 align-items-center input">
                <label class="col-form-label neue-roman mt-0 pl-0 pt-0 col-12 col-sm-3 pb-0 pr-1 input-label">{{ $t('components.service.modal.PayHref.3') }}<span class='require'> *</span></label>
                <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between col-12 col-sm-9">
                    <div class="col-12 col-sm-4">
                        <div class="col-10 d-flex flex-row justify-content-end">
                            <InputSimple type='text' :dataValue='this.data.on_sum' id='on_sum' @updateParentState="this.updateState" />
                        </div>
                        <div class="col-2 d-block d-sm-none d-flex flex-row justify-content-end">
                            <Popper class="bs-tooltip" :content='"hint"'/>
                        </div>
                    </div>
                    <div class="d-flex align-items-center pl-2 col-12 col-sm-7 justify-content-center">
                        <label class="col-form-label neue-roman mt-0 pl-0 pt-0 pb-0 pr-1 input-label">{{ $t('components.service.modal.PayHref.12') }}</label>
                        <div class="">
                            <SwitchSimple :dataValue='this.data.on_identified' id='on_identified' @updateParentState="this.updateState"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-1 d-none d-sm-block  d-flex flex-row justify-content-end">
                        <Popper class="bs-tooltip" :content='"Если не фиксировать сумму, то плательщик сможет ввести ее самостоятельно (подходит, напримерб для благотворительных платежей)"'/>
                    </div>
                </div>
            </div>
            <Select :label="$t('views.Payin.2')"  id='on_payform' :hotUpdate="true" :dataValue='this.data.on_payform' :keywords.sync='this.pay_sys_keyword' @updateParentState="this.updateState" :hint="'hint'"/>
            <Input type='text' :dataValue='this.data.on_ext_transact' :label="$t('components.service.modal.PayHref.2')" :require="true" id='on_ext_transact' @updateParentState="this.updateState" :hint="'Уникальный номер операции в учетной системе магазина'"/>
            <Input type='textarea' :dataValue='this.data.on_comment' :label="$t('components.service.modal.PayHref.4')" id='on_comment' @updateParentState="this.updateState" :hint="'Будет виден плательщику'"/>
            <Input type='text' :dataValue='this.data.on_free_param' :label="$t('components.service.modal.PayHref.5')" id='on_free_param' @updateParentState="this.updateState" :hint="'Придет в уведомлении'"/>
            <Input type='text' :dataValue='this.data.on_timeout' :label="$t('components.service.modal.PayHref.19')" id='on_timeout' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.18')"/>
            <p class="href pointer mt-2 mb-1" v-on:click="() => {this.showUrls = !this.showUrls}">Адреса уведомлений и возврата (URL)</p>
            <div v-if="this.showUrls">
                <Input type='text' :dataValue='this.data.on_url_success' :label="$t('components.service.modal.PayHref.6')" id='on_url_success' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.9')"/>
                <Input type='text' :dataValue='this.data.on_url_decline' :label="$t('components.service.modal.PayHref.7')" id='on_url_decline' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.10')"/>
                <Input type='text' :dataValue='this.data.on_url_callback' :label="$t('components.service.modal.PayHref.8')" id='on_url_callback' @updateParentState="this.updateState" :hint="$t('components.service.modal.PayHref.11')"/>
            </div>
        </div>

        <div class='d-flex mx-auto justify-content-center pt-2'>
            <Button :name="$t('components.service.modal.WidgetData.2')" class='mr-1' type='btn-outline-warning' 
            v-on:click='() => {
                this.close();
            }'/>
            <Button :name="$t('components.service.modal.WidgetData.4')" type='btn-warning' :disabled="!this.data.on_keyt_shop" v-on:click="() => {this.createCode()}"/>
        </div>
        
        <div v-if="this.payLink" class='d-flex flex-column mx-auto mt-4'>
            <div class="btn-group col-12 pl-1 pr-1 mb-3" role="group" aria-label="Basic outlined example">
                <Button
                    :name="$t('components.service.modal.PayHref.20')" class='col-12 col-sm-4' 
                    v-on:click="() => {
                        this.output_type = 'link';
                    }"
                    :type='this.output_type == "link" ? "btn-warning" : "btn-outline-warning"'
                />
                <Button
                    :name="$t('components.service.modal.PayHref.21')" class='col-12 col-sm-4' 
                    v-on:click="() => {
                        this.output_type = 'form';
                    }"
                    :type='this.output_type == "form" ? "btn-warning" : "btn-outline-warning"'
                />
                <Button
                    :name="$t('components.service.modal.PayHref.22')" class='col-12 col-sm-4' 
                    v-on:click="() => {
                        this.output_type = 'widget';
                    }"
                    :type='this.output_type == "widget" ? "btn-warning" : "btn-outline-warning"'
                />
            </div>

            <div v-if="this.output_type == 'link'">
                <h4 class="text-center">{{ $t('components.service.modal.PayHref.15') }}</h4>
                <div class="mb-3 d-flex flex-row align-items-end justify-content-between">
                    <a
                    class="break-all href pointer" :href="this.payLink">
                        {{ this.payLink }}
                    </a>
                </div>
                <div class="d-flex mx-auto justify-content-center mb-3">
                    <Button :name="$t('components.service.modal.PayHref.13')" type='btn-outline-warning' class='mr-1'  :icon_i="'c17 contain copy'" v-on:click="() => {this.copyLink(this.payLink)}"/>
                    <Button :name="$t('components.service.modal.PayHref.14')" type='btn-outline-href' :icon_i="'c17 contain ext-link'" v-on:click="this.redir(this.payLink)"/>
                </div>
            </div>

            <div v-if="this.output_type == 'form'">
                <h4 class="text-center">{{ $t('components.service.modal.PayHref.16') }}</h4>
                <pre class="d-flex fs_09 flex-row justify-content-center"
                    style="word-wrap: break-word;">
                    <code style="margin-top: -32px;">
                        {{ this.payForm }}
                    </code>
                </pre>
                <div class="d-flex mx-auto justify-content-center mb-3">
                    <Button :name="$t('components.service.modal.PayHref.13')" type='btn-outline-warning' class='mr-1'  :icon_i="'c17 contain copy'" v-on:click="() => {this.copyLink(this.payForm)}"/>
                </div>
            </div>

            <div v-if="this.output_type == 'widget'">
                <h4 class="text-center">{{ $t('components.service.modal.PayHref.22') }}</h4>
                <pre class="d-flex fs_09 flex-row justify-content-center"
                    style="word-wrap: break-word;">
                    <code>
                        {{ this.payObject }}
                    </code>
                </pre>
                <div class="d-flex mx-auto justify-content-center mb-3">
                    <Button :name="$t('components.service.modal.PayHref.13')" type='btn-outline-warning' class='mr-1'  :icon_i="'c17 contain copy'" v-on:click="() => {this.copyLink(this.payObject)}"/>
                </div>
            </div>

        </div>

    </div>
</template> 

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';
import InputSimple from '@/components/service/InputSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';

export default {
    components: {
        Button,
        Select,
        Input,
        Switch,
        InputSimple,
        SwitchSimple,
        Popper
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            doc: this.$store.state.modal.url.DOC,
            keywords: [],
            pay_sys_keyword: [],
            data: {
                on_payform: '',
                on_ext_transact: 'automatic!',
                on_sum: '',
                on_comment: '',
                on_keyt_shop: '',
                on_free_param: '',
                on_identified: '1',
                on_url_success: '',
                on_url_decline: '',
                on_url_callback: '',
                on_timeout: '60',
            },
            payLink: '',
            payForm: '',
            payObject: '',
            showUrls: false,
            output_type: 'link',
        }
    },
    mounted() {
        console.log(this.content);
    },
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    methods: {
        getKeyts(k) {
            this.keywords = k;
        },
        updateState(arg) {
            if (arg.id == 'on_identified') {
                if (arg.value == true) {
                    arg.value = '1';
                } else {
                    arg.value = '0';
                }
            }
            this.data[arg.id] = arg.value;
            if (arg.id == 'on_keyt_shop') {
                this.getListMerhcant(arg.value);
            }
        },
        getListMerhcant(arg) {
             this.axios.post('GetListMerchant', {data: {
                'keyt_pay': arg,
            }}).then(res => {
                res.data.body.forEach(e => {
                    this.pay_sys_keyword.push({code: e.form, value: e.name, how: e.how, type:e.type});
                })
            })
        },
        createCode() {
            let link_text = '?ext_transact=' + encodeURIComponent(this.data.on_ext_transact) + 
		    '&num_shop='     + encodeURIComponent(this.content.num    ) + 
		    '&keyt_shop='    + encodeURIComponent(this.data.on_keyt_shop  ) + 
		    '&identified='   + encodeURIComponent(this.data.on_identified ) + 
		    '&sum='          + encodeURIComponent(this.data.on_sum         ) + 
		    '&payform='      + encodeURIComponent(this.data.on_payform     ) +
		    '&comment='      + encodeURIComponent(this.data.on_comment     ) + 
		    '&free_param='   + encodeURIComponent(this.data.on_free_param  ) +
		    '&url_success='  + encodeURIComponent(this.data.on_url_success ) +
		    '&url_decline='  + encodeURIComponent(this.data.on_url_decline ) +
		    '&url_callback=' + encodeURIComponent(this.data.on_url_callback) + 
            '&timeout='      + encodeURIComponent(this.data.on_timeout);
	
	        // sign
            let sum = '';
	        if ( this.data.on_identified == '1' ) {
		        sum = this.data.on_sum;
            }
	        let signed_str = this.data.on_ext_transact + this.content.num + this.data.on_keyt_shop + this.data.on_identified + sum + this.data.on_payform + this.data.on_comment + this.data.on_free_param + this.data.on_url_success + this.data.on_url_decline + this.data.on_url_callback;
            let params = {
                signed_str: signed_str,
                skeys: this.content.skeys
            }

            this.axios.post('MerchantSign', {data: params}).then(res => {
                if (res.data.body.sign && res.data.body.url) {
                    this.payLink = res.data.body.url + link_text + '&sign=' + res.data.body.sign;
                    this.url = res.data.body.url;
                    this.payObject = 
`
let dataObject = {
    "frame_id": "${this.content.num}_id",
    "frame_name": "${this.content.num}_name",
    "pay_params": {
        "ext_transact": "${this.data.on_ext_transact}",
        "num_shop": "${this.content.num}",
        "keyt_shop": "${this.data.on_keyt_shop}",
        "identified": "${this.data.on_identified}",
        "sum": ${this.data.on_sum}",
        "payform": "${this.data.on_payform}",
        "comment": "${this.data.on_comment}",
        "free_param": "${this.data.on_free_param}",
        "url_success": "${this.data.on_url_success}",
        "url_decline": "${this.data.on_url_decline}",
        "url_callback": "${this.data.on_url_callback}",
        "proc_url": "${this.url}",
        "sign": "${res.data.body.sign}"
    },
    "onSuccess": function (result) {
        console.log(result) /// ${this.$t('components.service.modal.WidgetData.7')}
    },
    "onFail": function (result) {
        console.log(result) /// ${this.$t('components.service.modal.WidgetData.8')}
    }
}`;


                    this.payForm = 
`
<form method="post" action="${res.data.body.url}">
    <input type="hidden" name="ext_transact" value="${this.data.on_ext_transact}">
    <input type="hidden" name="num_shop" value="${this.content.num}">
    <input type="hidden" name="keyt_shop" value="${this.data.on_keyt_shop}">
    <input type="hidden" name="identified" value="${this.data.on_identified}">
    <input type="hidden" name="sum" value="${sum}">
    <input type="hidden" name="payform" value="${this.data.on_payform}">
    <input type="hidden" name="comment" value="${this.data.on_comment}">
    <input type="hidden" name="free_param" value="${this.data.on_free_param}">
    <input type="hidden" name="url_success" value="${this.data.on_url_success}">
    <input type="hidden" name="url_decline" value="${this.data.on_url_decline}">
    <input type="hidden" name="url_callback" value="${this.data.on_url_callback}">
    <input type="hidden" name="timeout" value="${this.data.on_timeout}">
    <input type="hidden" name="sign" value="${res.data.body.sign}">
    <input type="submit" value="Перейти к оплате">
</form>`
            }
        })

        },
        copyLink(arg) {
            let el = document.createElement("textarea");
            document.body.appendChild(el);
            el.value = arg;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },
        redir(arg) {
            window.open(arg, '_blank').focus();
        },
    },
}
</script>

<style lang="scss" scoped>
code {
    color: #d63384 !important;
}
</style>