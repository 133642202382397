<template>
    <div ref='s_cont' :id="id + '_s'"  :class="{'disable': this.disable, 'invalid': this.invalid, 'mh-ks': this.keyt}" class='input-container form-control d-flex flex-row align-items-center' tabindex='-1' v-on:click="this.showOpt()">
        <div v-if="this.keyt && this.keywords[this.name]" class="input-container-select" style="overflow: hidden; padding-top: 0; padding-bottom: 0;" :id="id">
          <div class="m-0 neue-roman mr-1 w-100">
            <div class='d-flex w-100'>
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; padding-right: 0px !important; width: 32px;'">
                            <div v-if="this.keywords[this.name].image">
                                <i class="item-i contain c28" :style="`background-image: url('${this.keywords[this.name].image}');`"></i>
                            </div>
                            <div v-else class="simp-bg k-symbol">
                                <span class="">
                                    {{this.keywords[this.name].symbol}}
                                </span>
                            </div> 
                        </div>
                        <div class="d-flex flex-column" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'">
                            <div class='href neue-roman d-flex flex-row'>
                                <span v-if="this.k_name && this.keywords[this.name].alias" class="d-flex flex-row align-items-center" :style="'white-space: nowrap'">
                                    <span class="lh-1">
                                        {{this.sized(this.keywords[this.name].alias)}}
                                    </span>
                                    <a v-if="this.keywords[this.name].explorer_link" :href="this.keywords[this.name].explorer_link" target="_blank">
                                        <i class='item-i c18 ml-2 ext-link'></i>
                                    </a>
                                </span>
                                <span v-else :style="'white-space: nowrap'">
                                    <span class="lh-1">
                                        {{ this.sized(this.keywords[this.name].code) }}
                                    </span>
                                </span>
                            </div>
                            <span 
                                class='grey light fs_09 lh-1'  
                                :title='this.keywords[this.name].name'
                            >
                                {{this.sizedName(this.keywords[this.name].value || this.keywords[this.name].name)}}
                            </span>
                        </div>
                    </div>
                    <div class="align-middle" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'">
                        <span class='neue-roman fs_13' v-html="this.sizedBalance(this.keywords[this.name].balance)"></span> 
                        <span class='grey fs_08'>&nbsp;{{this.keywords[this.name].curr}}</span>
                    </div>
                </div>
            </div>
        </div>
          <div class="c-arrow" @click="this.clickArrowSelect()">
            <i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i>
        </div>
        </div>
        <div 
            v-else-if="this.keyt_thin && this.keywords[this.name]" 
            class="input-container-select w-100 select-thin" 
            style="overflow: hidden;" :id="id"
        >
            <div class="m-0 neue-roman mr-1 w-100">
                <div class='d-flex w-100'>
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <div v-if="this.keywords[this.name].image">
                                    <i class="item-i contain c28 mr-1" :style="`background-image: url('${this.keywords[this.name].image}');`"></i>
                                </div>
                                <div v-else class="simp-bg k-symbol mr-1">
                                    <span class="">
                                        {{this.keywords[this.name].symbol}}
                                    </span>
                                </div> 
                            </div>
                            <div class="align-middle">
                                <div class='href neue-roman d-flex flex-row'>
                                    <span v-if="this.k_name && this.keywords[this.name].alias" class="d-flex flex-row align-items-center" :style="'white-space: nowrap'">
                                        <span>
                                            {{ this.sized(this.keywords[this.name].alias) }}
                                        </span>
                                        <a v-if="this.keywords[this.name].explorer_link" :href="this.keywords[this.name].explorer_link" target="_blank"><i class='item-i c18 ml-2 ext-link'></i></a>
                                    </span>
                                    <span v-else :style="'white-space: nowrap'">
                                        <span>
                                            {{this.sized(this.keywords[this.name].code)}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="align-middle">
                            <span class='neue-roman fs_13' v-html="this.sizedBalance(this.keywords[this.name].balance)"></span>  
                            <span class='grey fs_08'>&nbsp;{{this.keywords[this.name].curr}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-arrow" @click="this.clickArrowSelect()">
                <i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i>
            </div>
        </div>
        <div v-else class="input-container-select" :class="{'mh-ks': this.keyt}" :id="id">
            <span v-if="this.isSearch" v-show="this.showOptions" class="input-search-wrapper">
                <input class="input-search" ref='input_search' type="search" @input="inputSearchHandler" tabindex="-1"/>
            </span>
            <span 
                class="input-container-select-value" 
                v-if="!this.isFilterSelect" 
                v-show="!this.showOptions" 
                v-html="this.name ? this.name.n : this.defaultValue" 
                :class='{"grey": this.name.g}'
            ></span>
            <span 
                class="input-container-select-value" 
                v-else v-show="!this.isSearch || !this.showOptions" 
                v-html="this.name.n ? this.name.n : this.defaultValue" 
                :class='{"grey": !this.name.n}'
            ></span>
            <div v-if="!this.name.n || !this.isFilterSelect" class="c-arrow" @click="this.clickArrowSelect()">
                <i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i>
            </div>
            <div v-else-if="this.name.n && 
                this.isFilterSelect && 
                (!this.isSearch || !this.showOptions)" 
                @click="this.clearFilterSelect()"
            >
                <i class="item-i remove-val c20"></i>
            </div>
        </div> 
 
        
        <transition v-if="this.keyt" @enter="enter" @leave="leave">
            <div 
                ref='o_cont'
                :class="this.transformUp ? 'open-up' : ''"
                :style='!this.transformUp ? "max-height: " + this.listHeight + "px; top: 2.17rem;" : "top: 0;"' 
                v-if="showOptions && !disable" 
                class='content p-3 input-container-select-options'
            >
                <div v-if='this.keywords.length > 0' class="m-0 neue-roman">
                    <div :style="'max-height: ' + this.listHeight + 'px;'" class="d-flex flex-column">
                        <div 
                            class="mh-ks d-flex justify-content-between align-items-center" 
                            v-for="row in this.keywords" 
                            :key="row.keyt" 
                            v-on:click="() => {this.selectOption(row.code)}"
                        >
                            <div class="d-flex align-items-center">
                                <div 
                                    class="align-middle" 
                                    :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; padding-right: 0px !important; width: 32px'"
                                >
                                    <div v-if="row.image">
                                        <i class="item-i contain c28" :style="`background-image: url('${row.image}');`"></i>
                                    </div>
                                    <div v-else class="simp-bg k-symbol">
                                        <span>{{row.symbol}}</span>
                                    </div> 
                                </div>
                                <div 
                                    class="align-middle" 
                                    :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'"
                                >
                                    <div class='href neue-roman d-flex flex-row'>
                                        <span 
                                            v-if="this.k_name && row.alias" 
                                            class="d-flex flex-row align-items-center" 
                                            :style="'white-space: nowrap'"
                                        >
                                            <span>
                                                {{this.sized(row.alias)}}
                                            </span>
                                            <a v-if="row.explorer_link" :href="row.explorer_link" target="_blank">
                                                <i class='item-i c18 ml-2 ext-link'></i>
                                            </a>
                                        </span>
                                        <span v-else :style="'white-space: nowrap'">
                                            <span>
                                                {{this.sized(row.code)}}
                                            </span>
                                        </span>
                                    </div>
                                    <span class='grey light fs_09' :style="'white-space: nowrap'">
                                        {{this.sizedName(row.value || row.name)}}
                                    </span>
                                </div>
                            </div>
                            <div class="align-middle" style="padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;">
                                <span class='neue-roman fs_13' v-html="this.sizedBalance(row.balance)"></span> <span class='grey fs_08'>{{row.curr}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition v-else-if="this.keyt_thin" @enter="enter" @leave="leave">
            <div 
                ref='o_cont'
                :class="this.transformUp ? 'open-up' : ''"
                :style='!this.transformUp ? "max-height: " + this.listHeight + "px; top: 2.17rem;" : "top: 0;"' 
                v-if="showOptions && !disable" 
                class='content p-3 input-container-select-options'
            >
                <div v-if='this.keywords.length > 0' class="m-0 neue-roman">
                    <div :style="'max-height: ' + this.listHeight + 'px;'" class="d-flex flex-column">
                        <div
                            class="mh-ks d-flex justify-content-between align-items-center" 
                            v-for="row in this.keywords" 
                            :key="row.keyt" 
                            v-on:click="() => {this.selectOption(row.code)}"
                        >
                            <div class="d-flex align-items-center">
                                <div
                                    class="align-middle" 
                                    :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important; padding-right: 0px !important; width: 32px'"
                                >
                                    <div v-if="row.image">
                                        <i class="item-i contain c28" :style="`background-image: url('${row.image}');`"></i>
                                    </div>
                                    <div v-else class="simp-bg k-symbol">
                                        <span>
                                        {{row.symbol}}
                                        </span>
                                    </div> 
                                </div>
                                <div class="align-middle" :style="'padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;'">
                                    <div class='href neue-roman d-flex flex-row'>
                                        <span v-if="this.k_name && row.alias" class="d-flex flex-row align-items-center" :style="'white-space: nowrap'">
                                            <span>
                                                {{this.sized(row.alias)}}
                                            </span>
                                            <a v-if="row.explorer_link" :href="row.explorer_link" target="_blank">
                                                <i class='item-i c18 ml-2 ext-link'></i>
                                            </a>
                                        </span>
                                        <span v-else :style="'white-space: nowrap'">
                                            <span>
                                                {{this.sized(row.code)}}
                                            </span>
                                        </span>
                                    </div>
                                    <span v-if="row.name" class='grey light fs_09' :style="'white-space: nowrap'">
                                        {{this.sizedName(row.name)}}
                                    </span>
                                </div>
                            </div>
                            <div class="align-middle" style="padding-top: 0.4rem !important;padding-bottom: 0.4rem !important;">
                                <span class='neue-roman fs_13' v-html="this.sizedBalance(row.balance)"></span> <span class='grey fs_08'>{{row.curr}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition v-else @enter="enter" @leave="leave">
            <ul 
                ref='o_cont' 
                class="input-container-select-options" 
                :class="this.transformUp ? 'open-up' : ''"
                :style='!this.transformUp ? "max-height: " + this.listHeight + "px; top: 2.17rem;" : "top: 0;"' 
                v-if="showOptions && !disable && isSearch"
            >
                <li class="input-container-select-options-li" v-for="option in this.optionsList" :key="option.code" :class="{'acted': this.value == option.code}" v-on:click="() => {this.selectOption(option.code)}">
                    <label v-html="option.value || option.name"></label>
                </li>
            </ul>
            <ul 
                ref='o_cont' 
                class="input-container-select-options"
                :class="this.transformUp ? 'open-up' : ''"
                :style='!this.transformUp ? "max-height: " + this.listHeight + "px; top: 2.17rem;" : "top: 0;"' 
                v-else-if="showOptions && !disable"
            >
                <li class="input-container-select-options-li" v-for="keyword in this.keywords" :key="keyword.code" :class="{'acted': this.value == keyword.code}" v-on:click="() => {this.selectOption(keyword.code)}">
                    <label v-html="keyword.value || keyword.name"></label>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { gsap } from "gsap";
import InputSimple from '@/components/service/InputSimple';

export default {
    components: {
        InputSimple,
    },
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            showOptions: false,
            name: this.dataValue ? this.getOption(this.dataValue) : '',
            listHeight: '200',
            transformUp: false,
            elemWidth: false,
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            optionsList: [],
            isSetValue: false,
            arrowClick: false,
        }
    },
    props: {
        id: [String, Boolean],
        keywords: [Array, String, Boolean],
        dataValue: [String, Boolean],
        invalid: [Boolean],
        keyt: {
            default: false,
            type: [Boolean],
        },
        keyt_thin: {
            default: false,
            type: [Boolean],
        },
        hotUpdate: {
            default: false,
            type: [Boolean],
        },
        disable: {
            default: false,
            type: [Boolean]
        },
        isSearch: {
            default: false,
            type: [Boolean]
        },
        defaultValue: {
            default: '',
            type: [String],
        },
        isFilterSelect: {
            default: false,
            type: [Boolean],
        },
        isPaginationSelect: {
            default: false,
            type: [Boolean]
        }
    },
    mounted() {
        // console.log(this.keywords);
        // new ResizeObserver(outputsize).observe(this.$refs.s_cont),
        this.elemWidth = this.$refs.s_cont.offsetWidth;

        this.optionsList = this.keywords && this.keywords.length > 0 ? [...this.keywords] : [];
    },
    beforeUnmount() {
        document.querySelector('body').removeEventListener('click', this.clickClose);
    },
    watch: {
        dataValue(newV, oldV) {
            if (this.hotUpdate) {
                this.selectOption(newV, true);
            }
        }
    },
    methods: {
        // obs_call() {
        //     this.elemWidth = this.$refs.s_cont.offsetWidth;
        // }, 
        clickArrowSelect() {
            if (this.showOptions) {
                this.closeSelect();
                this.arrowClick = true;
            }
        },
        searchInList(arg) {
            if (!this.keywords) {
                return;
            }

        },
        sized(str) {
            console.log('elemWidth', this.elemWidth)
            if (this.elemWidth && this.elemWidth < 400 && str.length > 7) {
                return '* ' + str.substring(str.length - 5)
            } else if (this.elemWidth && this.elemWidth > 500 && str.length > 40) {
                return str.substring(0, 35) + '...';
            }
            return str;
        },
        sizedName(str) {
            if (!str) {
                return '';
            } else if (this.elemWidth < 300 && str.length > 10) {
                return str.substring(0, 6) + '...';
            } else if (this.elemWidth < 400 && str.length > 15) {
                return str.substring(0, 11) + '...';
            } else if (this.elemWidth < 500 && str.length > 20) {
                return str.substring(0, 15) + '...';
            } else if (this.elemWidth < 600 && str.length > 30) {
                return str.substring(0, 20) + '...';
            } else if (str.length > 34) {
                return str.substring(0, 35) + '...';
            }
            return str;
        },
        sizedBalance(str) {
            if (!str) {
                return '';
            }
            let arr = str.split('.')
            if (arr.length == 1) {
                return str;
            } else {
                if (arr[1].length > 8) {
                    arr[1] = arr[1].substring(0, 8);
                }
                return arr[0] + `.<span class="fs_09">${arr[1]}</span>`;
            }
        },
        updateState(repeat = false) {
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value,
                repeat: repeat,
            });
            this.blur();
        },
        setValue(repeat = false) {
            this.updateState(repeat);

            this.isSetValue = true;
        },
        blur() {
            this.$emit('blurInput', {
                id: this.id,
                value: this.value
            })
        },
        sortArr() {
            let arr = this.keywords;
            arr.sort((r1, r2) => {
                if (r1.code != this.value && r2.code != this.value ) {
                    return 0;
                }
                if (r1.code == this.value && r2.code != this.value) {

                    return -1;
                }
                if (r1.code != this.value && r2.code == this.value) {
                    return 1;
                }
            });
            // console.log(arr);
        },
        showOpt() {
            if (this.arrowClick) {
                this.arrowClick = false;
                return;
            }

            if (this.disable) {
                this.showOptions = false;
                document.querySelector('body').removeEventListener('click', this.clickClose);
                return;
            }
            // this.sortArr();

            if (this.isSearch) {
                this.optionsList = [...this.keywords];
            }
            
            this.listHeight = this.getHeight();
            this.transformUp = Number(this.listHeight) < 70

            if (this.transformUp) {
                this.listHeight = '200'
            }

            if (!this.isSearch || this.isSearch && !this.showOptions) {
                this.showOptions = !this.showOptions;
            }

            this.showOptions ? this.$refs.s_cont.focus() : this.$refs.s_cont.blur();
            document.querySelector('body').addEventListener('click', this.clickClose);

            this.isSearch ? document.querySelector('body').blur() : '';

            if (this.isSearch && this.showOptions && this.$refs.input_search) {
                this.$refs.input_search.focus();
            }
        },
        getHeight() {
            let list = this.$refs.s_cont.getBoundingClientRect();
            // let box = document.querySelector('#app').getBoundingClientRect();
            let box = {bottom: window.innerHeight};
            return String((box.bottom + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
        clearSearchInput() {
            if (this.$refs.input_search) {
                this.$refs.input_search.value = '';
            }
        },
        closeSelect() {
            this.showOptions = false;
            document.querySelector('body').removeEventListener('click', this.clickClose);

            // метод содержит проверку наличия инпута поиска и его очистку
            this.clearSearchInput();
        },
        clickClose(e) {
            if (!this.$el.contains(e.target)) {
                this.closeSelect();
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.6, height: '50%', duration: 0.2}, {opacity: 1, height: 'auto', duration: 0.2});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.6, height: 0, duration: 0.1, onComplete: done});
        },
        getOption(code) {
            if (this.keyt || this.keyt_thin) {
                let val = '';
                this.keywords.forEach((e, i) => {
                    if (e.code == code) {
                        val = i;
                        // console.log(e)
                    }
                });
                return val;
            } else {
                // console.log(code)
                let val = {};
                this.keywords.forEach(e => {
                    if (e.code == code) {
                        if (e.place) {
                            val.g = true;
                        } else {
                            val.g = false;
                        }
                        val.n = e.value || e.name;
                    }
                });
                return val;
            }
        },
        selectOption(value, repeat = false) {
            this.name = this.getOption(value);
            this.value = value;
            if (!repeat) {
                this.setValue(repeat);
            }

            /* если есть поиск, после выбора значения селекта поиск очищается
            метод clearSearchInput содержит проверку существования поиска */
            this.clearSearchInput();
            this.isSearch ? this.closeSelect() : '';
        },
        inputSearchHandler(event) {
            const searchValue = event.target.value;

            const searchResult = this.keywords.filter(keyword => 
                keyword.value.toLowerCase().includes(searchValue.toLowerCase()));

            this.optionsList = searchResult.length > 0 ? [...searchResult] : [];
        },
        clearFilterSelect() {
            this.name = '';
            this.value = '-1';

            this.updateState();
        }
    },
}
</script>

<style lang="scss" scoped>
.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 1.6rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
}
.mh-ks {
    min-height: 55px;
    height: 56px;
    border-bottom: 1px solid var(--bs-border-color);
}
.select-thin {
    max-height: 38px;
}
.simp-bg {
    background-color: $color-href;
    border: 1px solid $color-href;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}
.input-container:focus:not(.disable) {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.input-container.disable:focus,
.input-container.disable:focus-visible {
    border: 1px solid var(--bs-border-color);
    outline: none;
    box-shadow: none;
}

.input-search-wrapper {
    display: flex;
    padding-left: 30px;
    width: 100%;
    background-image: url('~@/assets/icons/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
}
.input-search {
    width: calc(100% - 20px);
    border: none;
}
.input-search:focus,
.input-search:focus-visible {
    border: none;
    outline: none;
}
.disable {
    background-color: $color-lightgrey;
    cursor: default !important;
}
.acted {
    background-color: $color-light;
    font-family: HelveticaNeue bold;
}
.alter-col {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
}
.k-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 1rem;
        display: flex;
        align-items: center;
    }
}
.k_tr {
    padding: 0.375rem 0.75rem !important; 
}
.input-container {
        padding: 0;
        position: relative;
        cursor: pointer;
        &-select {
            width: 100%;
            z-index: 2000;
            outline:none;
            border: none;
            padding: 0.375rem 0.75rem;
            background: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: 'HelveticaNeue roman';
            .c-arrow, .c-arrow {
                height: 24px;
            }
            &-value {
                height: min-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-options {
                padding: 0;
                border-bottom: 1px solid #ced4da;
                border-left: 1px solid #ced4da;
                border-right: 1px solid #ced4da;
                left: -0.06rem;
                right: -0.06rem;
                background: #fff;
                position: absolute;
                overflow: auto;
                max-height: 280px;
                z-index: 3000;
                margin: 0;
                list-style-type: none;
                font-family: 'HelveticaNeue roman';
                border-radius: 0 0 0.25rem 0.25rem;
                &-li {
                    padding: 0.375rem 0.75rem;
                    label {
                        font-weight: regular;
                        cursor: pointer;
                    }
                    border-top: 1px solid $color-light;
                }
                &-li:hover {
                    background-color: $color-lightgrey;
                }
            }
        }
    .keyt-name {
        white-space: nowrap;

        @media (max-width: 768px) {
            overflow: hidden;
            max-width: 100px;
            text-overflow: ellipsis;
        }
    }
    .lh-1 {
        line-height: 1;
    }
}

.open-up {
  transform: translateY(-100%);
  border-radius: 0.25rem;
  border-top: 1px solid #ced4da;
}

</style>