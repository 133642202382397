<template>
  <div v-if="type === 'base'" class="menu_item" :class="{ active: open }" @click="toggle" ref="menu_item">
    <h4 class="menu_title" :class="{ active: open }">
      {{ item.title[lang] ? item.title[lang] : item.title[langDefault] }}
    </h4>
    <Transition @enter="animateOpen" @leave="animateClose">
      <div class="menu_container" ref="menu_container" v-show="open">
        <div class="menu_list">
          <component
            v-for="(menu_item, index) in item.groups"
            :key="index"
            :is="menu_item.list ? 'div' : 'router-link'"
            :id="menu_item.list ? '' : menu_item.to"
            class="list_item"
            :class="{ active: activeSubIndex === index }"
            :to="menu_item.to"
            @click="menu_item.list ? sub_toggle(index, $event) : ''"
            sub_list
          >
            <div class="d-flex align-items-center gap-3">
              <Icon :name="menu_item.icon" />
              <span>{{ menu_item.subtitle[lang] ? menu_item.subtitle[lang] : menu_item.subtitle[langDefault] }}</span>
            </div>

            <Icon
              v-if="menu_item.list"
              :class="{ active_icon: activeSubIndex === index }"
              name="arrow"
              size="c20"
              :active="activeSubIndex === index"
            />
          </component>
        </div>
        <Transition @before-enter="subAnimateOpen" @leave="subAnimateClose">
          <div class="sub_list" v-show="activeSubIndex !== null" ref="sub_list">
            <div v-for="(listItem, index) in subMenuItems" :key="index">
              <router-link :id="listItem.to" class="sub_list_item" :to="listItem.to" @click="toggle">{{
                listItem.content[lang] ? listItem.content[lang] : listItem.content[langDefault]
              }}</router-link>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </div>

  <div
    v-else-if="type === 'burger' && item.length"
    class="burger_menu_item"
    :class="{ active: this.open }"
    v-on:click="this.toggle"
    id="burger"
    ref="burger_item"
  >
    <Icon name="burger" :size="this.mobile ? 'c24' : 'c30'" :active="this.open" />

    <Transition @enter="animateOpen" @leave="animateClose">
      <div class="burger_container" :class="{ mobile_menu_list: this.mobile }" ref="burger_container" v-show="open">
        <div class="menu_list" :class="{ mobile_user_list: this.mobile }">
          <div v-for="(burger, key) in item" :key="key">
            <component
              v-for="(menu_item, index) in burger.groups"
              :key="index"
              :is="menu_item.list ? 'div' : 'router-link'"
              class="burger_list_item"
              :to="menu_item.to"
              @click="menu_item.list ? sub_toggle(index, $event, key) : toggle()"
            >
              <div class="burger_menu_title" :class="{ active: this.activeSubIndex === `${key}-${index}` }">
                <div class="d-flex align-items-center gap-3">
                  <Icon :name="menu_item.icon" />
                  <span>{{
                    menu_item.subtitle[lang] ? menu_item.subtitle[lang] : menu_item.subtitle[langDefault]
                  }}</span>
                </div>

                <Icon
                  v-if="menu_item.list"
                  :class="{ active_icon: this.activeSubIndex === `${key}-${index}` }"
                  name="arrow mini"
                  size="c20"
                  :active="this.activeSubIndex === `${key}-${index}`"
                />
              </div>

              <Transition @enter="burgerAnimateOpen" @leave="burgerAnimateClose" :css="false">
                <div class="burger_sub_list" v-show="activeSubIndex === `${key}-${index}`">
                  <div v-for="(listItem, index) in subMenuItems" :key="index">
                    <router-link class="sub_list_item" :to="listItem.to" @click="toggle">{{
                      listItem.content[lang] ? listItem.content[lang] : listItem.content[langDefault]
                    }}</router-link>
                  </div>
                </div>
              </Transition>
            </component>
          </div>
        </div>
      </div>
    </Transition>

    <!-- <Transition v-else @enter="mobileOpen" @leave="mobileClose">
      <div class="mobile_menu_list" v-show="open">
        <div v-for="(burger, index) in item" :key="index" class="mobile_menu_container" @click="userClick">
          <span class="mobile_menu_title">{{
            burger.title[lang] ? burger.title[lang] : burger.title[langDefault]
          }}</span>
          <div class="d-flex align-items-center gap-3">
            <component
              v-for="(menu_item, subIndex) in burger.groups"
              :key="subIndex"
              :is="menu_item.list ? 'div' : 'router-link'"
              class="mobile_list_item"
              :to="menu_item.to"
              @click="menu_item.list ? sub_toggle(subIndex, $event, index) : toggle()"
            >
              <Icon :name="menu_item.icon" />
              <span class="mobile_menu_text">{{
                menu_item.subtitle[lang] ? menu_item.subtitle[lang] : menu_item.subtitle[langDefault]
              }}</span>

              <Transition @enter="subMubileOpen" @leave="subMubileClose">
                <div v-if="activeSubIndex === `${index}-${subIndex}`" class="mobile_wrapp" @click="close_sub">
                  <div class="mobile_sub_list" @click="userClick">
                    <div @click="close_sub" class="mobile_close">X</div>
                    <div class="d-flex align-items-center justify-content-center gap-3">
                      <Icon :name="menu_item.icon" />
                      <span class="mobile_menu_title">{{
                        menu_item.subtitle[lang] ? menu_item.subtitle[lang] : menu_item.subtitle[langDefault]
                      }}</span>
                    </div>

                    <div class="sub_list_container" v-for="(listItem, index) in subMenuItems" :key="index">
                      <router-link class="sub_list_item" :to="listItem.to" @click="toggle">{{
                        listItem.content[lang] ? listItem.content[lang] : listItem.content[langDefault]
                      }}</router-link>
                    </div>
                  </div>
                </div>
              </Transition>
            </component>
          </div>
        </div>
      </div>
    </Transition> -->
  </div>

  <div
    v-else-if="type === 'user'"
    class="user_menu_item"
    :class="{ active: this.open }"
    @click="this.toggle"
    ref="user_item"
  >
    <img
      v-if="this.$store.state.userData.avatar"
      class="user_img"
      :class="{ user_img_sm: this.mobile }"
      :src="this.$store.state.userData.avatar"
    />
    <img v-else class="user_img" :class="{ user_img_sm: this.mobile }" src="@/assets/user.svg" />
    <Transition @enter="animateOpen" @leave="animateClose">
      <div class="burger_container" :class="{ mobile_menu_list: this.mobile }" v-show="open">
        <div class="user_list" :class="{ mobile_user_list: this.mobile }" @click="userClick">
          <div class="user_info">
            <div class="position-relative">
              <img
                v-if="this.$store.state.userData.avatar"
                class="user_img_big"
                :src="this.$store.state.userData.avatar"
              />
              <img v-else class="user_img_big" src="@/assets/user.svg" />
              <router-link to="/info_reg" class="edit" @click="this.toggle">
                <Icon name="edit" size="c20" />
              </router-link>
            </div>
            <div class="user_info_container">
              <div class="user_name">
                <span>{{ getGreeting() }},</span>
                <span>{{ this.$store.getters.get_user_name }}</span>
              </div>
              <div class="id_container">
                <div v-if="this.idents.length > 0">
                  <span
                    v-for="row in this.idents"
                    :key="row.code"
                    class="cli_1 user_id"
                    :data-clipboard-target="'#_' + row.code"
                  >
                    <span> {{ $t("components.service.topmenu.1") }} {{ row.ident }}</span>
                    <Icon class="pointer" name="copy" size="c24" :active="true" />
                    <input type="hidden" :id="'_' + row.code" :value="row.ident" />
                  </span>
                </div>
                <div v-else class="d-flex align-items-center justify-content-center">
                  <span class="cli_1 user_id" :data-clipboard-target="'#_' + this.$store.state.userData.id">
                    <span>ID: {{ this.$lib.getTokenString(this.$store.state.userData.id, 4) }}</span>
                    <Icon class="pointer" name="copy" size="c24" :active="true" />
                    <input
                      type="hidden"
                      :id="'_' + this.$store.state.userData.id"
                      :value="this.$store.state.userData.id"
                    />
                  </span>
                </div>
                <div class="button" @click="logout">
                  Logout
                  <Icon name="logout" size="c24" />
                </div>
              </div>
            </div>
          </div>
          <div v-for="(burger, key) in item" :key="key">
            <component
              v-for="(menu_item, index) in burger.groups"
              :key="index"
              :is="menu_item.list ? 'div' : 'router-link'"
              class="burger_list_item"
              :to="menu_item.to"
              @click="menu_item.list ? sub_toggle(index, $event, key) : toggle()"
            >
              <div class="burger_menu_title" :class="{ active: this.activeSubIndex === `${key}-${index}` }">
                <div class="d-flex align-items-center gap-3">
                  <Icon :name="menu_item.icon" />
                  <span>{{
                    menu_item.subtitle[lang] ? menu_item.subtitle[lang] : menu_item.subtitle[langDefault]
                  }}</span>
                </div>

                <Icon
                  v-if="menu_item.list"
                  :class="{ active_icon: this.activeSubIndex === `${key}-${index}` }"
                  name="arrow mini"
                  size="c20"
                  :active="this.activeSubIndex === `${key}-${index}`"
                />
              </div>

              <Transition @enter="burgerAnimateOpen" @leave="burgerAnimateClose" :css="false">
                <div class="burger_sub_list" v-if="activeSubIndex === `${key}-${index}`">
                  <div v-for="(listItem, index) in subMenuItems" :key="index">
                    <router-link class="sub_list_item" :to="listItem.to" @click="toggle">{{
                      listItem.content[lang] ? listItem.content[lang] : listItem.content[langDefault]
                    }}</router-link>
                  </div>
                </div>
              </Transition>
            </component>
          </div>

          <div class="burger_list_item" v-show="open" @click="sub_toggle(0, $event, 'last')">
            <div class="burger_menu_title" :class="{ active: this.activeSubIndex === 'last-0' }">
              <div class="d-flex align-items-center gap-3">
                <Icon name="star" />
                <span>{{ $t("components.service.topmenu.2") }}</span>
              </div>

              <Icon
                :class="{ active_icon: this.activeSubIndex === 'last-0' }"
                name="arrow mini"
                size="c20"
                :active="this.activeSubIndex === 'last-0'"
              />
            </div>

            <Transition @enter="burgerAnimateOpen" @leave="burgerAnimateClose" :css="false">
              <div class="burger_sub_list" v-if="activeSubIndex === 'last-0'" @click="userClick">
                <LangSwitcher />
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import { gsap } from "gsap";
import Icon from "@/components/icons/Icon.vue";
import LangSwitcher from "@/components/service/LangSwitcher";

export default {
  components: {
    Icon,
    LangSwitcher,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "base",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lang: this.$i18n.locale,
      langDefault: process.env.VUE_APP_I18N_LOCALE,
      idents: this.$store.state.userData.ident,
      open: false,
      activeSubIndex: null,
      subMenuItems: [],
    };
  },
  mounted() {
    new ClipboardJS(".cli_1");
  },
  methods: {
    toggle() {
      console.log("toggle", this.open);
      this.open = !this.open;
      if (this.activeSubIndex !== null) {
        this.activeSubIndex = null;
        this.subMenuItems = [];
      }

      if (this.open) {
        document.addEventListener("click", this.handleClickOutside);
        document.addEventListener("touchstart", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
        document.removeEventListener("touchstart", this.handleClickOutside);
        this.activeSubIndex = null;
        this.subMenuItems = [];
      }
    },

    userClick(event) {
      event.stopPropagation();
    },
    close_sub(event) {
      event.stopPropagation();
      this.activeSubIndex = null;
      this.subMenuItems = [];
    },

    sub_toggle(index, event, groupKey = null) {
      event.stopPropagation();

      let menuItem;
      let subIndex;
      if (groupKey === "last") {
        menuItem = "last-0";
        subIndex = "last-0";
      } else if ((this.type === "burger" || this.type === "user") && groupKey !== null) {
        menuItem = this.item[groupKey].groups[index];
        subIndex = `${groupKey}-${index}`;
      } else {
        menuItem = this.item.groups[index];
        subIndex = index;
      }
      if (menuItem.list || menuItem == "last-0") {
        if (this.activeSubIndex === subIndex) {
          this.activeSubIndex = null;
          this.subMenuItems = [];
        } else {
          this.activeSubIndex = subIndex;
          this.subMenuItems = menuItem.list;
        }
      }
    },
    handleClickOutside(event) {
      const menuItem = this.$refs.menu_item;
      const burgerItem = this.$refs.burger_item;
      const userItem = this.$refs.user_item;
      const isClickInside =
        (menuItem && menuItem.contains(event.target)) ||
        (burgerItem && burgerItem.contains(event.target)) ||
        (userItem && userItem.contains(event.target));

      if (!isClickInside) {
        this.toggle();
      }
    },
    logout() {
      this.axios.post("logout").then((res) => {
        this.$store.commit("USER_DATA", false);
        this.$router.push("/login");
      });
    },
    getGreeting() {
      const now = new Date();
      const hour = now.getHours();

      if (hour >= 6 && hour < 12) {
        return this.$t("components.service.topmenu.3");
      } else if (hour >= 12 && hour < 18) {
        return this.$t("components.service.topmenu.4");
      } else if (hour >= 18 && hour < 22) {
        return this.$t("components.service.topmenu.5");
      } else {
        return this.$t("components.service.topmenu.6");
      }
    },

    animateOpen(target) {
      gsap.fromTo(
        target,
        { height: 0 },
        { height: this.mobile ? "calc(100vh - 54px)" : "auto", duration: 0.17, ease: "power1.inOut" }
      );
    },
    animateClose(target, done) {
      gsap.to(target, { height: 0, duration: 0.17, ease: "power1.inOut", onComplete: done });
    },
    mobileOpen(target) {
      gsap.fromTo(target, { height: 0 }, { height: "calc(100vh - 54px)", duration: 0.15, ease: "power1.inOut" });
    },
    mobileClose(target, done) {
      gsap.to(target, { height: 0, duration: 0.15, ease: "power1.inOut", onComplete: done });
    },
    subMubileOpen(target) {
      gsap.fromTo(target, { width: 0 }, { width: " 100vw", duration: 0.1, ease: "power1.inOut" });
    },
    subMubileClose(target, done) {
      gsap.to(target, { width: 0, duration: 0.1, ease: "power1.inOut", onComplete: done });
    },
    subAnimateOpen(target) {
      gsap.fromTo(target, { width: 0 }, { width: "300px", duration: 0.1, ease: "power1.inOut" });
    },
    subAnimateClose(target, done) {
      gsap.to(target, { width: 0, duration: 0.1, ease: "power1.inOut", onComplete: done });
    },
    burgerAnimateOpen(el, done) {
      gsap.set(el, { height: "auto" });
      gsap.from(el, {
        height: 0,
        duration: 0.1,
        ease: "power1.inOut",
        onComplete: done,
      });
    },
    burgerAnimateClose(el, done) {
      gsap.to(el, {
        height: 0,
        duration: 0.1,
        ease: "power1.inOut",
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$menu-item-width: 130px;
$menu-item-height: 100%;
$menu-padding: 20px;
$menu-gap: 20px;
$menu-border-radius: 10px;
$user-img-size: 30px;
$user-img-sm-size: 24px;
$user-img-big-size: 90px;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin hover-active {
  &:hover,
  &.active {
    background-color: $menu-bg-active;
    color: $menu-active;
  }
}

%menu-item-base {
  user-select: none;
  position: relative;
  cursor: pointer;
  @include flex-center;
  height: $menu-item-height;
  color: $menu-base;
}

%container-base {
  position: absolute;
  display: flex;
  cursor: default;
  top: 100%;
  overflow: hidden;
  border-bottom-right-radius: $menu-border-radius;
  border-bottom-left-radius: $menu-border-radius;
  border: 1px solid $menu-border;
}

%list-item-base {
  padding: 0 $menu-padding;
  height: 60px;
  display: flex;
  justify-content: space-between;
  gap: $menu-gap;
  align-items: center;
  color: $menu-base;
  cursor: pointer;
  @include hover-active;
}

.menu_item {
  @extend %menu-item-base;
  max-width: $menu-item-width;
  width: $menu-item-width;
  min-width: $menu-item-width;
  @include hover-active;
}

.burger_menu_item,
.user_menu_item {
  @extend %menu-item-base;
}

.menu_title {
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  padding: 3px;
  margin: 0;
  &.active {
    font-weight: bold;
  }
}

.burger_menu_title {
  @extend %list-item-base;
}

.user_info {
  display: flex;
  background: $menu-bg-dark;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $menu-border;
}

.user_info_container {
  height: 112px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.user_name {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user_img {
  width: $user-img-size;
  height: $user-img-size;
  min-width: $user-img-size;
  min-height: $user-img-size;
  border-radius: 50%;
}

.user_img {
  width: $user-img-size;
  height: $user-img-size;
  min-width: $user-img-size;
  min-height: $user-img-size;
  border-radius: 50%;
}

.user_img_big {
  width: $user-img-big-size;
  height: $user-img-big-size;
  min-width: $user-img-big-size;
  min-height: $user-img-big-size;
  border-radius: 50%;
}

.user_img_sm {
  width: $user-img-sm-size;
  height: $user-img-sm-size;
  min-width: $user-img-sm-size;
  min-height: $user-img-sm-size;
  border-radius: 50%;
}

.menu_list {
  background: $menu-bg2;
  z-index: 1;
  width: 300px;
}

.user_list {
  @extend .menu_list;
  border: 1px solid $menu-border;
  width: 400px;
}

.mobile_user_list {
  @extend .menu_list;
  overflow: scroll;
  border: 1px solid $menu-border;
  width: 100%;
  border-radius: 8px;
}

.menu_container {
  @extend %container-base;
  left: 0;
}

.burger_container {
  @extend %container-base;
  flex-direction: column;
  right: 0;
}

.mobile_burger_container {
  @extend %container-base;
  flex-direction: column;
  left: 0;
}

.list_item {
  @extend %list-item-base;
  border-top: $menu-border 1px solid;
  border-collapse: collapse;
  
  &:first-child {
    border-top: none;
  }
}

.burger_list_item {
  border-top: $menu-border 1px solid;
  height: auto;
  display: flex;
  flex-direction: column;

  &:first-child {
    border-top: none;
  }
}

%sub-list-base {
  text-wrap: nowrap;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: $menu-gap;

  > div > a {
    width: 100%;
    display: block;
  }
}

.sub_list {
  @extend %sub-list-base;
  background-color: $menu-bg-dark;
  padding: 20px;
}

.burger_sub_list {
  @extend %sub-list-base;
  margin: 20px;
}

.edit {
  position: absolute;
  @include flex-center;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $menu-border;
  outline: 1px solid #5d6a99;
}

.button {
  font-size: 15px;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $menu-border;
  background: $menu-bg;
  border-radius: 8px;
}

.id_container {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 10px;
}

.user_id {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  font-size: 12px;
}

.sub_list_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid $menu-border;
}

.sub_list_item {
  color: $menu-base;

  &:hover {
    color: $menu-active;
  }
}

.icon {
  transform: rotate(0deg);
  transition: all 0.2s ease-out;
}

.active_icon {
  transform: rotate(180deg);
}

.mobile_menu_list {
  width: 100vw;
  position: fixed;
  top: 54px;
  left: 0;
  height: calc(100vh - 54px);
  background: $menu-bg;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.mobile_menu_list::-webkit-scrollbar {
  width: 8px;
}

.mobile_menu_list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.mobile_menu_list::-webkit-scrollbar-track {
  background: transparent;
}

.mobile_menu_container {
  padding: 15px 10px;
  background: $menu-bg2;
  border-radius: $menu-border-radius;
  border: 1px solid $menu-border;
  word-wrap: break-word;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.mobile_list_item {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  max-width: 120px;
  width: 100%;
  text-align: center;
}

.mobile_menu_title {
  font-size: 16px;
  font-weight: bold;
  color: $menu-active;
}

.mobile_menu_text {
  text-align: center;
  font-size: 15px;
  max-width: 120px;
  color: $menu-base;
}

.mobile_wrapp {
  position: fixed;
  z-index: 5;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.mobile_close {
  position: absolute;
  border: 1px solid $menu-border;
  border-right: none;
  top: 40px;
  left: -40px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: $menu-bg2;
  color: $menu-base;
  padding: 5px;
  border-radius: 40%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  z-index: 9;
}

.mobile_sub_list {
  z-index: 10;
  position: fixed;
  padding: 50px 20px;
  z-index: 10;
  right: 0;
  top: 0;
  width: 70vw;
  height: 100vh;
  background: $menu-bg2;
  border-radius: $menu-border-radius;
  border: 1px solid $menu-border;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
