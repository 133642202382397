<template>
    <div class='container-xl container-fluid p-0'>
        <div class="d-flex justify-content-between align-items-center p-0 mb-2">
          <h4 v-if="this.get_done_text" class='neue-roman mt-1 d-flex p-0 align-items-center justify-content-start' 
            :class="{'color-green': (Number(this.get_done) >=1 && Number(this.get_done) <= 3) || Number(this.get_done) === 8,
              'color-warning': Number(this.get_done) == -2 || Number(this.get_done) == -3,
              'color-acent': Number(this.get_done) == 0 || (Number(this.get_done) >= 4 && Number(this.get_done) <= 7)
          }">
            <i class='item-i mr-1 c28 contain' :class="{'check': (Number(this.get_done) >=1 && Number(this.get_done) <= 3) || Number(this.get_done) === 8,
              'x_red': Number(this.get_done) == -2 || Number(this.get_done) == -3,
              'clock': Number(this.get_done) == 0 || (Number(this.get_done) >= 4 && Number(this.get_done) <= 7)}"></i>
            {{this.get_done_text}}
          </h4>
          <div v-else class="loader-div">
            <loading :active="this.loader"
                   :is-full-page="false"
                   :height='20'
                   :width='50'
                   :loader='"dots"'
                   :color='"#008D3E"'
                   :opacity='0'
                   />
            </div>
          <i class='item-i x_red pointer contain' v-on:click='this.close'></i> 
        </div>
        <PayStatusTemplate 
            :message="this.getMessage()"
            :get_transact="this.get_transact" 
            :get_sum="this.get_sum"
            :get_sum2="this.get_sum2"
            :get_res="this.get_res"
            :get_done="this.get_done"
            :exp_post="this.export_postings"
            :loading="this.loader"
            @getParamsExtTransact="this.getParamsExtTransact" 
        >
            <template v-slot:loader>
                <loading :active="this.loader"
                   :is-full-page="false"
                   :height='20'
                   :width='50'
                   :loader='"dots"'
                   :color='"#008D3E"'
                   :opacity='0'
                   />
            </template>

            <template v-slot:links>
                <ul>
                    <li><router-link :to="this.repeat_url" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.1') }}</router-link></li>
                    <li><router-link :to="'History'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.2') }}</router-link></li>
                    <li v-if="this.k_name"><router-link :to="'/keyts/list'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.11') }}</router-link></li>
                    <li v-else><router-link :to="'/keyts/list'" class="href" v-on:click="this.close">{{ $t('components.service.modal.PayStatus.3') }}</router-link></li>
                    <li v-if="this.newVA"><router-link :to="this.disabled ? '' : '/link'" class="href" :class="this.disabled ? 'opacity-75' : 'opacity-100'" v-on:click="this.disabled ? '' : this.close">{{ $t('components.service.modal.PayStatus.9') }}</router-link></li>
                    <li v-if="this.newVA"><router-link :to="this.disabled ? '' : '/link'" class="href" :class="this.disabled ? 'opacity-75' : 'opacity-100'" v-on:click="this.disabled ? '' : this.close">{{ $t('components.service.modal.PayStatus.10') }}</router-link></li>
                </ul>
            </template>
        </PayStatusTemplate>
    </div>
</template>
  
<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import ClipboardJS from 'clipboard';
  import Button from '@/components/service/Button';
  import PayStatusTemplate from '@/components/service/PayStatusTemplate';
  
  export default {
      components: {
          Loading,
          Button,
          PayStatusTemplate
      },
      data() {
        return {
          result: this.$store.state.modal.content.result,
          result_text: this.$store.state.modal.content.advanced.result_text ? this.$store.state.modal.content.advanced.result_text : this.$store.state.modal.content.result_text,
          success: this.$route.query.success,
          repeat_url: 'Payin',
          loader: true,
          get_res: false,
          get_sum: false,
          get_sum2: false,
          get_transact: false,
          get_done: '0',
          get_done_text: '',
          k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
          export_postings: false,
          timeout: false,
          exp_status: false,
          disabled: true,
          newVA: false,
        }
      },
      props: {
        close: [Function],
      },
      methods: {
          getTitle() {
            if (this.result === '0') {
              return this.$t('components.service.modal.PayStatus.4');
              
            } else if (this.success !== '0') {
              return this.$t('components.service.modal.PayStatus.5');
            }
          },
          getMessage() {
            if (this.result_text) {
              return this.result_text;
            } else {
              return false;
            }
          },
          getParamsExtTransact() {
            if (this.get_done == '0') {
              this.get_done_text = '';
              this.get_res = false;
            } 
            let params = {ext_transact_num : this.$store.state.modal.content.ext_transact,
                              only_data: '1'
                            }
                this.loader = true;
                this.axios.post('GetParamsExtTransact', {no_loader: true, data: params}).then(res => {
                  this.loader = false;
                  // console.log('GETPARAMSEXTTRANSACT: ')
                  // console.log(res);
                  if (res.data.body.result == '0') {
                    this.newVA = res.data.body.advanced.cmd == "create_custom_curr"
                    if(res.data.body.advanced.done == '1') {
                      this.disabled = false;
                    }
                    let shop = [];
                    let pay = [];
                    res.data.body.tables.forEach(e => {
                      if (e.name == 'shop') {
                        shop = e;
                      }
                      if (e.name == 'pay') {
                        pay = e;
                      }
                      if (e.name == 'export_postings') {
                        this.export_postings = e.colvalues;
                      }
                    });
                    this.get_sum = '';
                    if (res.data.body.advanced.id_shop == res.data.body.advanced.id_pay) {
                      this.get_sum = pay['colvalues'][0].sum + " " + pay['colvalues'][0].curr_text;
                      this.get_sum2 = shop['colvalues'][0].sum + " " + shop['colvalues'][0].curr_text;
                    } else {
                      if (this.$store.state.userData.id != res.data.body.advanced.id_shop) {
                        this.get_sum = pay['colvalues'][0].sum + " " + pay['colvalues'][0].curr_text;
                      } else if (this.$store.state.userData.id != res.data.body.advanced.id_pay) {
                        this.get_sum = shop['colvalues'][0].sum + " " + shop['colvalues'][0].curr_text;
                      }
                    }
                    
                    this.get_res = res.data.body.advanced.result_text;
                    this.get_done = res.data.body.advanced.done;
                    this.get_done_text = res.data.body.advanced.done_text;
                    new ClipboardJS('.cli_transact');
                    this.get_transact = res.data.body.advanced.transact;

                    if (this.export_postings) {
                      if (this.export_postings.length > 0) {
                        this.exp_status = this.export_postings[0]['export_status'];
                        if (this.exp_status === '1' || this.exp_status === '13' || this.exp_status === '14') {
                          return;
                        }
                      }
                    }
                    if (this.get_done !== '0' && (!this.export_postings || this.export_postings.length == 0)) {
                      return;
                    } else if ((this.get_done === '0' && this.exp_status == '0') ||  (this.exp_status !== '1' || this.exp_status !== '13' || this.exp_status !== '14')) {
                      this.timeout = setTimeout(() => {this.getParamsExtTransact()}, 5000);
                    }
                  } else {
                    this.get_res = res.data.body.result_text;
                  
                    if (this.timeout) {
                      clearTimeout(this.timeout);
                    }
                  }
                })
          }
      },
      mounted() {
          if (this.$store.state.modal.content) {
              if (this.$store.state.modal.content.ext_transact) {
                this.getParamsExtTransact();
              }
              if (this.$store.state.modal.content.type == 'pay') {
                this.repeat_url = 'Pay';
              }
              if (this.$store.state.modal.content.type == 'transfer') {
                this.repeat_url = 'transfer_id';
              }
              if (this.$store.state.modal.content.type == 'transfer_self') {
                this.repeat_url = 'transfer_self';
              }
          }
          // console.log(this)
      },
      beforeUnmount() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      }
  }
  </script>


<style lang="scss" scoped>
.loader-div {
    position: relative;
    min-height: 20px;
    width: 50px;
    z-index: 2000;
  }
</style>