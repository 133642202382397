export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First time here?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone or email:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password recovery"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User found"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period from"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period to"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters/Comment"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No operations found"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of transactions"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receipts in"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation types"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal, payments for services"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal transfers"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice payment"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers to own accounts"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating crypto vouchers"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation of crypto vouchers"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming/outgoing"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= equal"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ not equal"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> more"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ greater or equal"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< less"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ less or equal"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed successfully"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account replenishment"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not selected"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange on the stock exchange"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto money transfer"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers within your wallet"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Passport"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration date:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last authorization:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenish"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bills"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation password"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm transactions, you need to create a confirmation password."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular payments"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assets"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Information"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In group"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment groups"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of payment"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer account"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save template"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refillable account"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save template"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account replenishment"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer details"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check translation"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Information"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address replenishment"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated address"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the list of tasks"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer address"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat operation"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of transactions"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of tokens"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Account:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection of the deal"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection period:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as template"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to another user"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The protection code is the code that the recipient must enter to complete the transaction. \nThe recipient will see the protected transfer immediately in the “Transaction History” section, but will be able to use the money only after entering the protection code in the transaction card. \nIf the payment has expired, the funds are returned to the sender."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer address"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to own accounts"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers inside the wallet"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])}
    },
    "Keys": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remainder"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new wallet"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete selected"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of tokens"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete accounts?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the account?"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of token"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account type"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create token"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of tokens"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The anti-phishing setting is an additional protection mechanism when working with the web banking."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The welcome phrase you set will be displayed at the top of any page of your account."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The correct phrase will show you that you are on the right site, the wrong phrase will be a signal: without performing operations, you must urgently contact the security service of our system."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable anti-phishing:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing string:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antiphishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes will take effect after the next login."])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuring access by IP address will not allow you (or an intruder) to work with the system from an address that is not registered in the list of allowed."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restriction of access to the cabinet by IP addresses"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add addresses"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address block: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own IP:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address list"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From address"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To address"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addresses"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbid"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter IP address"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect IP address entered"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address or block of addresses specified."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No IP address is allowed for authorization. You will not be able to access your account."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Access"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The magic word is an additional security setting."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the magic word is enabled, then each time you log in, the system will ask you to enter some characters (by their serial number) from the magic word."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask for the magic word:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When entering the office:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When paying through a merchant:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using the card:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic word"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys significantly increase system security."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you enter the program, you will be asked not only to enter your login and password, but also another one-time key. Activation is performed one by one. The last key is valid until you create a new series of keys."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use one-time keys"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of keys"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time keys"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"New Password\" and \"Confirm Password\" do not match."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String from 4 to 63 characters. \nLatin characters, numbers and symbols are allowed"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"New Password\" and \"Confirm Password\" do not match."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not contain Cyrillic"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create confirmation password"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change confirmation password"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the main"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String from 4 to 65 characters. \nLatin characters, numbers and symbols are allowed"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you've forgotten your password, you can recover it by answering your secret question."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can change your security question/answer."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret question"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret question"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["residual "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading pair:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange type:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer by market - exchange will be used at the most favorable rate at the time of execution of the order. Attention, the amount to be received may change and differ from the calculated one depending on the orders at the time of the exchange."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fixed price transfer occurs according to the amount and rate you specified. An order will be placed. If the specified rate is very different from the market rate, then closing the order may take a long time."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate (forecast):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course not determined, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it is recommended to set the course yourself"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the market"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(quick exchange with best counteroffer)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed rate"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(submit an order with a given exchange rate)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matching accounts"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily change"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily fluctuations"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade volume per day"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sum cannot be equal to zero!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see the summary data, select the trading pair."])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot exchange identical assets"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Summary"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar offers"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter offers"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My applications"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest deals"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given away"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deals"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No applications"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully implemented"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing not started"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing started"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely canceled"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed and canceled"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissions"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No applications"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min summ to send"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max summ to send"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min summ to get"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max summ to get"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of attempts to complete a transaction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency to send"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency to get"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for send"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for get"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of subdealers"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account not selected"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period from"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period to"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date type not selected"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which part is fixed and which part is variable"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount given is fixed"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount to be received is fixed"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type order"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user wants to quickly exchange for the best counter offers"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange at a strictly specified rate"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing has not started"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing started"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially completed"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fully completed"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely canceled"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially implemented and cancelled"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing asset"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming asset"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date type"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give the amount"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get amount"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P offers"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller ID"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer code"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller verified"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only for verified users"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's impossible to make a deal with yourself"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of crypto-assets"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assets"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create token"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapped to another asset"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name - this is the full name of the crypto-asset, as it will be seen by everyone: the issuer itself, buyers, sellers, processing, etc. A string of no more than 40 characters"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker is a short name of a crypto-asset, an abbreviation of 3 to 10 characters long"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the category that best fits your crypto-asset"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported formats: png, jpg, jpeg. \nFile size no more than 1 Mb, 500*500 pixels"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe why this asset is issued by the issuer, in what area it works or will work, what is its mission"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose / mission / purpose:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit cost:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of decimal places for the amount. \nFor example, the accuracy of most fiat currencies such as the dollar, the euro is 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of the asset being issued. \nThe amount of the issue will be immediately available on your account after the completion of the operation of creating a crypto-asset."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declared unit price:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligated person:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision to issue a crypto-asset:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export transactions to the blockchain:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain type:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable VA usage:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions/Sanctions:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify, citizens or tax residents of which countries from the list below are prohibited from using a crypto-asset in their turnover:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other restrictions and conditions:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting address:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White paper address:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other contacts:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an asset"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name and purpose"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial characteristics"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits and restrictions"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspecified"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed peg to fiat currencies and other assets"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I AM"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nobody is obligated"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me (include sponsorship)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement of a virtual asset"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-asset means a digital representation of value or right that can be transferred and stored electronically using blockchain technology. Crypto-assets do NOT include fiat currencies, securities and other financial instruments and assets subject to separate government regulation."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit crypto asset"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for refusal to accept"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror's account"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror's account in the binding currency"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offeror is a representative of the issuer of an asset in purchase/sale transactions on the primary market. \nThe account is passive. \nCan be an asset issue account"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account is passive, linked in currency. \nMust belong to the same user as the previous account"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow users to purchase an asset at ATMs"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket Purchase Form"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary market transactions are carried out through gateways"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftermarket Sales Form"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary market transactions are carried out through gateways"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow transactions with an asset on the exchange"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow asset transactions via P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are an individual entrepreneur or a representative of a legal entity, you need to download the decision file on the release of a digital asset in PDF format. \nThe solution must contain all the main characteristics of the virtual asset and be signed with an electronic signature of the individual entrepreneur or this legal entity. \nAn example of a decision to issue a digital asset can be found on the pages of our website in the \\\"Documents\\\" section"])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placing a virtual asset"])}
      }
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remainder"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new address"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Selected"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete accounts?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the account?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assets found"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts found"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renaming an address"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete addresses?"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address type"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account type"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an address"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a new address"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about transactions"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization (login to personal account, mobile application, etc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify when \"Performing financial transactions using a card\" event"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses (any payments, transfers, payment for services, etc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash inflow"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving a letter by internal mail"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice receipt for payment"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing security settings"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm operations with a code from the message"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm authorization with the code from the message"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm when \"Performing financial transactions using the card\" event"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm changes to security settings with a code from a message"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm expenses with the code from the message"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes not saved, save?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not save"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt date"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of processing"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of payments"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["including successful"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["including erroneous"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payments"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provider"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st parameter"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payments found"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can make multiple payments at the same time. \nTo do this, you must first create a file with a description of payments in the format \"XLS\", \"XLSX\", \"CSV\" or \"XML\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File analysis"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Information"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payments:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for total cost:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment File"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal account"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payments"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading a payment file"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass payments and transfers"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mass payment service allows you to quickly transfer funds to other users and perform payment transactions to service providers."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In chapter"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download payment file"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can upload an Excel (XLS, XLSX, CSV) or XML file."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task list"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allows you to view the statuses of previously created requests for mass payouts."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can create a file in an Excel spreadsheet editor."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download sample file"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important notes:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file format is CSV, XLS or XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file must be without headers."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to prevent long numbers from being converted to the exponent format (for example, \"5.46546E 15\") and leading zeros are not cut off, you can put a single quote character (') in front of the number, or set the cell to text format before entering data."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format of columns for payments to service providers"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of columns in the file:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nSpecified from the list of operators given"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \n(For all mobile operators, you can specify 7000 as the operator code - in this case, the operator will be determined automatically by phone number.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is specified in the ticker of the asset from which the transfer is made.\nIf such a ticker is not available, the system's conversion rate is automatically applied to the transaction."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment comment"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe value of the parameter required by the operator is written, for example, the phone number. \nMany operators are limited to one payment parameter, but if the number of parameters is more than one, then you need to specify them all in columns adjacent to the first one."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameter N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameters can be viewed by going to the payment of any operator:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column format for bulk transfers to users"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nSet to 0 or not set at all."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is displayed in the ticker of the account from which the asset is transferred.\nIf such a ticker is not available, the system's conversion rate is automatically applied to the transaction."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment comment"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary's account"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThis specifies the account number of the user who needs to make the transfer (20 digits)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format of columns for mass issuance of loans by a loan agent"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThis line indicates that the payments are loans."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe amount is indicated in the currency of the loan product."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit comment"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling is not required, you can leave it blank; \nlength no more than 255 characters."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrower ID"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nPlease note that the borrower must have an Mfo-BorrowPerson digital passport."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan product code"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nYou can see it in the list of loan products on the manual provision of a loan."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nJust the number of periods (exactly which periods are used is defined in the loan product, for example, it can be days, weeks, months, etc.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator code (form number)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nFilling in this and the following fields is not necessary, if they are not filled out, then the borrower will be able to independently withdraw the loan later through his personal account."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel in case of error"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nCancel (1) the issued credit if the withdrawal transaction fails, or leave it for later self-withdrawal (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nThe value of the parameter required by the operator is written, for example, the phone number. \nMany operators are limited to one payment parameter, but if the number of parameters is more than one, then you need to specify them all in columns adjacent to the first one."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment option 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment parameter N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last message"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No requests"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload one or more files. \nThe maximum file size is 10Mb."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Requests"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handled by the operator"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request created"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work in progress, waiting for a response from the operator"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request closed"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request closed"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new messages"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New message from operator"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new messages"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to archive"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replenishment"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No templates"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete pattern no."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename Template"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterparty information"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Account / E-mail / Phone"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Crypto address / E-mail / Phone"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control Signature"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My stores"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores not found"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Widget Code"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment link"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete store"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the store?"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a store"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment mode"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a store"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An electronic store registered in the system allows you to accept payments from customers through the system merchant."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a store, electronic stores, exchange offices and any other Internet resources that wish to accept payments to their address are registered."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name or trade mark"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store URL"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief description of the store"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Type"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop active"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secret key"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow auto payout"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send results to script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script Address"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund after successful payment"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After an unsuccessful payment"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 30 characters. \nValid characters: latin letters, numbers"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used to check the integrity and validity of the transmitted data by the payment system (when the user's store sends it to the merchant) and the store (when receiving payment results)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the list of stores"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of invoices"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoices found"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Asset"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External asset"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyms"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If completed, the ad will only be available to users from the specified countries"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tie it to an asset"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviated"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisites"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer direction"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad is active"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only via link"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only to verified users"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to previous"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an ad"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own external asset"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not tie"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorten course"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return back"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account in such an asset"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify course"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course is correct"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorten course"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return back"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum is greater than maximum"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where and how you want to receive the transfer. For example: Transfer in the bank application to the phone number +499990001111 (recipient Alexander N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm transfer"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested details"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What details the taker should send so that you can make the transfer to him"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you like to receive: funds in an internal asset, for example, BTC, or in an external asset, for example, TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please comment on the specifics of this deal, for example: \\\"The external asset is transferred only during a personal meeting\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of values to which the external asset belongs, for example, \\\"Cash\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the asset for display on the platform"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The asset can also be named using words from the list"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching with an existing asset"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example: litres, pieces, hours, boxes"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example: L, pcs, hrs, bx"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many digits can be after the decimal point in asset values"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have chosen to create a new asset that does not yet exist, please fill in its characteristics"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time given to parties to meet the conditions"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An internal asset is an asset that exists within the \\\"New Reality\\\" system. An external asset is an asset that does not exist within the \\\"New Reality\\\" system."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not tied"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel deal"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm participation in the transaction"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse the deal"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend waiting time"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling a deal"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user's status in the transaction is not defined. \nSomething went wrong"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Arbitration"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to Arbitration"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify about transfer completion"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm receipt of transfer"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the deal"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit rating"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal No."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give away"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesman"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give it away"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You receive"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactions"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deals"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefit"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction code"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active trades"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My deals"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal asset"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset description"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ads"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my advertisements"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My advertisements"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New ad"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External asset"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal asset"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I exchange"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I receive"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limits"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication date"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade request"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesman"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time-out"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time for parties to act"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give it away"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You receive"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a deal"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account selected"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount does not fall within the range:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asset"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I give it away"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["internal"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I get"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal assets"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets not found"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External assets"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["external"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request arbitration"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Assets"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bound to Asset"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units of Measurement"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period From"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period To"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Assets"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bound to Asset"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisites"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount limit"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting time (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad is active"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only via link"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only to verified users"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit external asset"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonyms"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind to asset"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units of measurement"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbreviation"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose all"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ATMs"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to expand the table to full screen"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validator list"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create crypto ATM"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit crypto ATM"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main information"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation site description"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalized"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-normalized"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating mode"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch break"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use existing accounts"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new accounts"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic details"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating hours (reference information)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No crypto machines found"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM number"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitude"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitude"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-dealer ID"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transit account"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of a cryptomat"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing a cryptomat"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic checks"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a check"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write-off account"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actively"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not active"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No checks"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an electronic receipt"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write-off account"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denomination of each check"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of checks"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checks created"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation of an electronic check"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit account"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, an error occurred!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM collection"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add collection"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show archival collections"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show canceled collections"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collections"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection application submitted"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding collection"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispenser"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of cards per application"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill acceptor"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash collectors"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note for collectors"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on collection"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading out of order"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace the bill acceptor cassette"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentary on the cassette"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all cards from the Reject card dispenser cassette"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all cards from the tube"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point number is a required field that must be filled in"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette ID 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills in cassette 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount in cassette 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cassette rating 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bills in cassette 2"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Account"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Account"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian letters are allowed, as well as hyphens and apostrophes."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters are allowed, as well as hyphen, space and apostrophe."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters are allowed, as well as hyphens, quotation marks, spaces, numbers, and apostrophes."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLC My Company"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin letters (uppercase and lowercase), numbers, symbols _ - . ! $ % % ^ * = “ are allowed."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords don't match"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User data"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email address or phone number. The phone number is in international format, for example: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully registered"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in to your personal account"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account will be checked by our employee, we will inform you about the results of its activation later"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent with a link to confirm registration."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To your email address"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the first authorization, you will be asked to verify your phone number"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not found"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Choice"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transaction"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common parameters"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes in account balances"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the operation:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the beginning of the operation:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra options"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations in the blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next try:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal protection"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection period:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection code:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template options"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template name:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel operation"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat operation"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Template"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сlose"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction number"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment date"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment form number"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income/Expense"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing address balances"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm enrollment"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return money to sender"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspicious operation"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not found"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter confirmation password"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat operation"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations history"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of accounts"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My assets"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about new assets"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional issue"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of virtual assets"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an address"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locality"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Country"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercity"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locality"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clarification"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for technical support"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add message"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload one or more files. \nThe maximum file size is 10Mb."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a widget for"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment in"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of payment"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions on Success"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions on failure"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code generation / payment links"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store transaction no."])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional parameter"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification URL"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after successful payment"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where the client will return after an error"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL where notification of payment results will be sent"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the amount"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Link"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice lifetime in minutes, default 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link validity period"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Store"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you see this, the form was not submitted (but you shouldn't see this)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional emission"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto-asset"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage account"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission account"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue size"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comment"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enrollment"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted formats:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown format"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size too large, maximum size:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or drag files to upload here"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files uploaded"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other security settings"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation required"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization must be confirmed with a one-time key"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization must be confirmed with a magic word"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration must be confirmed by the code from the SMS message"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line from"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["symbols"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good morning"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good afternoon"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good evening"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good night"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shown:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the page"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm payment"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate return fee"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return payment"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash in the blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML risk"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For enrollment"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you agree to the amended terms, please confirm your enrollment:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If these conditions do not suit you, you can return the funds to the sender's account minus return costs:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the amount"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has AML risk"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["therefore, the commission for its enrollment has been recalculated"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thus, the amount to be credited is"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new arrivals found"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
      }
    }
  }
}