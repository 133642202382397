<template>
    <div class="d-flex flex-column flex-sm-row mt-3 p-0 mr-0  g-3 align-items-center address">
        <label :for="id" class="col-form-label mt-0 pl-0 pt-0 col-12 col-sm-3 pb-0 pr-1 input-label">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        <div class="col-12 col-sm-9 mt-0 p-0 address-wrapper">
            <!-- <InputSimple :id="id" :invalid='this.invalid' :invalidHint='this.invalidHint' :autocomplete="this.autocomplete" :type=type :disabled='this.disabled' @blurInput="this.blure" :regCheck='this.regCheck' :dataValue='this.dataValue' @updateParentState="this.setValue"/> -->
            <div
				class='form-control gr-address'
                :class="{'disabled': this.disabled, 'pointer': !this.disabled}"
                v-on:click="this.showParams"
				data-trigger="hover"  data-placement="auto right" data-container="body" data-html="true"
				>
                {{ this.dField.value_text }}
            </div>
            <Popper v-if="this.hint" class="bs-tooltip" :content='this.hint'/>
            <div v-else-if="this.unvisible" class="mw34"></div>
            <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')"/>
            <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
            <SlotPopper v-if="this.unvisible"  :content="$t('views.InfoReg.4')">
                <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :disabled="this.is_public == '2' ? true : false" :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
            </SlotPopper>
        </div>
    </div>
</template>

<script>
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';
import SlotPopper from '@/components/service/SlotPopper';

export default {
    components: {
        Popper,
        SlotPopper,
        SwitchSimple
    },
    data() {
        return {
            addr_v: this.addr ? this.addr : {},
            dField: this.field ? this.field : {},
            sizeW: this.$store.state.resize.w,
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        id: [String, Boolean],
        label: [String],
        hint: [String, Boolean],
        addr: [Object, Boolean], 
        field: [Object, Boolean], 
        require: [Boolean],
        disabled: [String, Boolean],
        invalid: [Boolean],
        invalidHint: [String, Boolean],
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        is_public: [Boolean, String],
        verification: [Boolean]
    },
    methods: {
        setValue(arg) {
            console.log('setValue address not modal', arg);
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        setTextValue(arg) {
            this.$emit('updateParentTextState', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', { 
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
        showParams() {
            this.$store.commit('MODAL', {
                                    action: true,
                                    type: 'address',
                                    content: {
                                        field: this.dField,
                                        addr: this.addr_v,
                                        updateState: (arg, text) => {
                                            console.log('updateState');
                                            console.log('address not modal arg', arg);
                                            console.log('address not modal text', text);
                                            this.setValue(arg);
                                            this.dField.value_text = text;

                                            if (arg.new_addr) {
                                                this.addr_v = arg.new_addr;
                                            }
                                        },
                                    },
                                    }
                            );
        },
    },

}
</script>

<style lang="scss" scoped>
.address {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .gr-address {
        resize: none;
        display: inline-block;
        height: auto;
        min-height: 34px;
    }
}
.mt-1 {
    margin: 0 !important;
}
.col-form-label {
    line-height: 1.2 !important;
}

.require {
    color: red;
}
.disabled {
    background-color: #e9ecef;
}

</style>