<template>
    <transition name="error-container">
        <div class="error-popup error">
            <div class="error-container content p-4 col-xl-6 col-lg-8 col-10 neue mx-auto">
                <div class="error-wrapper d-flex justify-content-between align-items-center">
                    <div class="error-info d-flex align-items-center">
                        <div class="error-picture mr-2">
                            <div><i class="item-i error-picture c60"/></div>
                        </div>
                        <div class="error-text-info">
                            <h4 class="error-title">
                                {{ this.$t('views.Error.1') }}
                            </h4>
                            <div class="error-text">
                                {{ this.errorText ? this.errorText : '' }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <i class="item-i error-close c34 pointer" @click="this.closePopup()"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        errorText: [String],
    },
    methods: {
        closePopup() {
            this.$emit('close', {
            })
        }
    },
    mounted() {
      setTimeout(() => this.closePopup(), 30000);
    },
}
</script>

<style lang="scss">
    .error-popup {
        position: fixed;
        z-index: 4000;
        padding-top: 117px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        outline: none;
        overflow: auto;
        opacity: 0;
        animation: ani 0.3s forwards;
    }

    @keyframes ani {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }

    .error-container {
        background: $color-invalid!important;
        color: #fff;
    }
</style>